import React from "react";
import { Form } from "react-bootstrap";
import JiraTicket from "../JiraTicket";

export default function UpdateEffectiveDateFormFields(props) {
  return (
    <React.Fragment>
      <div className="mb-3">
        <label className="me-2">PID:</label>
        <br />
        <b className="text-primary">{props.pid}</b>
      </div>
      <div className="mb-3">
        <label className="me-2">Manufacturer:</label>
        <br />
        <b className="text-primary">{props.manufacturer_name}</b>
      </div>
      <Form.Group className="mb-3" controlId="date">
        <Form.Label>
          Effective Date <small className="text-danger">*</small>
        </Form.Label>
        <Form.Control required type="date" name="date" defaultValue={props.date} />
      </Form.Group>
      <JiraTicket />
    </React.Fragment>
  );
}
