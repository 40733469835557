import React, { useEffect, useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import ChangeParentSiteButton from "./ChangeParentSiteButton";

const ChildSites = (props) => {
  const [data, setData] = useState(props.child_sites);

  const columns = useMemo(
    () => [
      {
        Header: "Covered Entity",
        accessor: "entity_name",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <>
            <div>
              <b>{tableProps.row.original.entity_name}</b>
            </div>  
            <small>{`${tableProps.row.original.address1} ${tableProps.row.original.city} ${tableProps.row.original.state} ${tableProps.row.original.zip}`}</small>
          </>
        ),
      },
      {
        Header: "340B ID",
        accessor: 'id_340b',
        filter: 'fuzzyText',
        Cell: (tableProps) => (
          <>
          <div>
            <a href={`/entities/${tableProps.row.original.id}`} target="_blank">
              {tableProps.row.original.id_340b}
            </a>
          </div>
          </>
        )
      },
      {
        Header: "Grant Number",
        Cell: (tableProps) => (
          <>
            <div>
              {tableProps.row.original.grant_number}
            </div>
          </>
        )
      },
    ],
    []
  );


  return (
    <React.Fragment>
          <div className="mb-3">
            <small className="text-muted">Need to update Parent Site?</small>
            <ChangeParentSiteButton child_sites={data} current_parent_site={props.current_parent_site}/>
          </div>
        <>
      <div>
        <CustomTable columns={columns} data={data} />
      </div>
        </>
    </React.Fragment>
  );
};

export default ChildSites;