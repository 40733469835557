import React, { useEffect } from "react";
import { useState, useMemo } from "react";
import CustomTable from "./CustomTable";
import FormModal from "./FormModal";
import { Form, Button, NavLink } from "react-bootstrap";
import { set } from "lodash";
import activationStateBadgeClass from "./Users/activationStateBadgeClass";
import { Tab, Tabs } from "react-bootstrap";
import titleCase from "./utils/SnakeToTitleCase";
import RecordCount from "./utils/RecordCount";

function InviteUsers(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);

  const InviteUserFormFields = (props) => {
    return (
      <React.Fragment>
        {/* hidden field */}
        <input type="hidden" name="user[current_user_email]" value={""} />
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control required name="user[email]" placeholder="Enter email..." />
        </Form.Group>
        <Form.Group className="mb-3" controlId="first_name">
          <Form.Label>First Name</Form.Label>
          <Form.Control required name="user[first_name]" placeholder="Enter first name..." />
        </Form.Group>
        <Form.Group className="mb-3" controlId="last_name">
          <Form.Label>Last Name</Form.Label>
          <Form.Control required name="user[last_name]" placeholder="Enter last name..." />
        </Form.Group>
        <Form.Group className="mb-3" controlId="role">
          <Form.Label>Admin Role</Form.Label>
          <Form.Select name="user[role]">
            <option value="basic_support_admin">Basic Support Admin</option>
            <option value="advanced_support_admin">Advanced Support Admin</option>
            <option value="admin_support_manager">Admin Support Manager</option>
          </Form.Select>
        </Form.Group>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="bg-body-tertiary p-4 rounded-4">
        <small className="text-muted">Want to invite a new support admin user?</small>
        <button
          className="btn btn-primary btn-sm ms-3"
          onClick={() => {
            setShowInviteUserModal(true);
          }}>
          Invite Support Admin
        </button>
      </div>
      <FormModal
        show={showInviteUserModal}
        title="Invite User"
        buttonText="Submit"
        buttonType="primary"
        modalId="invite_user_modal"
        fields={<InviteUserFormFields props={props} />}
        method="POST"
        action={`/admins/invite`}
        dismiss={() => {
          setShowInviteUserModal(false);
        }}
      />
    </React.Fragment>
  );
}

const Admins = (props) => {
  const [data, setData] = useState(
    useMemo(
      () =>
        props.users.map((obj) => {
          return {
            ...obj,
            full_name: `${obj.first_name} ${obj.last_name}`,
            locked: obj.locked ? "True" : "False",
            decorate_prospect: obj.prospect === true ? "True" : "False",
          };
        }),
      []
    )
  );
  const [showModalUpdateUser, setShowModalUpdateUser] = useState(false);
  const [modalUpdateUserId, setModalUpdateUserId] = useState(null);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [showModalUnlock, setShowModalUnlock] = useState(false);
  const [modalUnlockId, setModalUnlockId] = useState(null);

  const UpdateUserFormFields = (obj) => {
    return (
      <React.Fragment>
        <Form.Group className="mb-3" controlId="first_name">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            type="text"
            name="user[first_name]"
            placeholder="Enter first name..."
            defaultValue={obj.user.first_name}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="last_name">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
            type="text"
            name="user[last_name]"
            placeholder="Enter last name..."
            defaultValue={obj.user.last_name}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="role">
          <Form.Label>Admin Role</Form.Label>
          <Form.Select required type="text" name="user[role]" defaultValue={obj.user.role}>
            <option value="">{`< Make A Selection >`}</option>
            <option value="super_admin">Super Admin</option>
            <option value="basic_support_admin">Basic Support Admin</option>
            <option value="advanced_support_admin">Advanced Support Admin</option>
            <option value="admin_support_manager">Admin Support Manager</option>
          </Form.Select>
        </Form.Group>        
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            type="text"
            name="user[email]"
            placeholder="Enter email..."
            defaultValue={obj.user.email}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="phone">
          <Form.Label>Phone</Form.Label>
          <Form.Control type="text" name="user[phone]" placeholder="Enter phone..." defaultValue={obj.user.phone} />
        </Form.Group>
      </React.Fragment>
    );
  };

  // Function to send the POST request
  const sendPostRequest = async (id, activation_token) => {
    await fetch(`users_mgmt/${id}/activate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({
        id,
        activation_token,
      }),
    });
    window.location.reload();
  };

  function ActivateButton(props) {
    if (props.status != "active") {
      return (
        <Button
          className="btn-sm btn-secondary btn"
          onClick={() => {
            sendPostRequest(props.id, props.activation_token);
          }}>
          Activate
        </Button>
      );
    }
  }

  function UnlockButton(props) {
    if (props.unlock_token) {
      return (
        <Button
          className="btn-sm btn-secondary btn"
          style={{ marginLeft: 10 }}
          onClick={() => {
            setModalUnlockId(props.id);
            setShowModalUnlock(true);
          }}>
          Unlock
        </Button>
      );
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <div className="text-nowrap">
            {tableProps.row.original.deleted_at ? (
              <>
                <i className="fa fa-close me-2 text-danger" title="Deleted User"></i>{" "}
                <i className="text-danger">{tableProps.value}</i>
                <div className="text-danger fst-italic">
                  <small>Deleted {tableProps.row.original.deleted_at}</small>
                </div>
              </>
            ) : (
              <>
                <a href={`/user/${tableProps.row.original.id}`} target="_blank">
                  <i className="fa fa-user me-2"></i>
                </a>
                {tableProps.row.original.full_name}
              </>
            )}
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        filter: "fuzzyText",
        Cell: (row) => <small className="text-muted">{row.value}</small>
      },
      {
        Header: "Activation State",
        accessor: "activation_state",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <div className={"badge text-uppercase fw-normal bg-" + activationStateBadgeClass(tableProps.row.original.activation_state)}>
             {tableProps.row.original.activation_state}
          </div>
        )
      },
      {
        Header: "Admin Role",
        accessor: "role",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <div className="badge bg-light text-dark text-uppercase fw-normal">
             {titleCase(tableProps.row.original.role) || "None"}
          </div>
        )
      },
      {
        Header: "Locked",
        accessor: "locked",
        filter: "fuzzyText",
        Cell: (row) => (
          <>
            <div
              className={`badge ${!row.row.original.unlock_token ? "bg-light text-dark" : "bg-danger text-light"} fw-normal text-uppercase`}>
              {row.row.original.unlock_token ? "True" : "False"}
            </div>
            <UnlockButton unlock_token={row.row.original.unlock_token} id={row.row.original.id} />
          </>
        )
      },
      {
        Header: "Action",
        Cell: (tableProps) =>
          props.approver && tableProps.row.original.activation_token && tableProps.row.original.crypted_password ? (
            <div>
              <ActivateButton
                status={tableProps.row.original.activation_state}
                id={tableProps.row.original.id}
                activation_token={tableProps.row.original.activation_token}
              />
            </div>
          ) : tableProps.row.original.activation_state == "active" ? (
            <React.Fragment>
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="click to edit"
                style={{ display: "inline-flex", alignItems: "center" }}>
                <Button
                  className="btn btn-sm"
                  onClick={() => {
                    setModalUpdateUserId(tableProps.row.original.id);
                    setUserToUpdate(tableProps.row.original);
                    setShowModalUpdateUser(true);
                  }}>
                  <i className="fa-solid fa-pen"></i>
                </Button>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div data-bs-toggle="tooltip" data-bs-placement="top" title="unactive user">
                <Button className="btn btn-sm" disabled={true}>
                  <i className="fa-solid fa-pen"></i>
                </Button>
              </div>
            </React.Fragment>
          ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <InviteUsers props={props} />
      <div className="mt-3">
        <RecordCount record_count={data.length} record_type="support admin" />
        <CustomTable columns={columns} data={data} />
      </div>
      <FormModal
        show={showModalUpdateUser}
        title={`Edit User - ${data.find((row) => row.id == modalUpdateUserId)?.email}, ${
          data.find((row) => row.id == modalUpdateUserId)?.id
        }`}
        buttonText="Update User"
        modalId={`edit_user_modal_${modalUpdateUserId}`}
        buttonType="primary"
        fields={<UpdateUserFormFields user={userToUpdate} />}
        method="POST"
        action={`/admins/${data.find((row) => row.id == modalUpdateUserId)?.id}/update`}
        dismiss={() => {
          setShowModalUpdateUser(false);
        }}
      />

      <FormModal
        show={showModalUnlock}
        title="Unlock User Account?"
        buttonText="Unlock"
        buttonType="primary"
        modalId={`unlock_modal_${modalUnlockId}`}
        fields={`Are you sure you want to unlock ${data.find((row) => row.id == modalUnlockId)?.full_name}'s account?`}
        method="POST"
        action={`/users/${data.find((row) => row.id == modalUnlockId)?.id}/unlock`}
        dismiss={() => {
          setShowModalUnlock(false);
        }}
      />
    </React.Fragment>
  );
};

export default Admins;
