import React from "react";
import { useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import RecordCount from "../utils/RecordCount";

const Manufacturers = (props) => {
  const [data, setData] = useState(
    useMemo(
      () =>
        props.manufacturers.map((obj) => ({
          ...obj
        })),
      []
    )
  );

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        filter: "fuzzyText",
        Cell: (tableProps) => <b><a href={`/manufacturers/${tableProps.row.original.key}`}><i className="fa fa-industry me-2"></i></a>{tableProps.value}</b>
      },
      {
        Header: "Code Name",
        accessor: "key",
        filter: "fuzzyText",
        Cell: (row) => <small className="text-muted">{row.value}</small>
      },
      {
        Header: "Networks",
        accessor: "networks",
        filter: "fuzzyText",
        Cell: (row) => <>{row.value.map(item => (<span className="badge bg-light text-dark fw-normal text-uppercase">{item}</span>))}</>
      },
      {
        Header: "WO Geofence?",
        accessor: "has_geofence",
        filter: "fuzzyText",
        Cell: (row) => <>{row.value ? "Yes" : "No"}</>
      },
      {
        Header: "Attestations?",
        accessor: "has_attestations",
        filter: "fuzzyText",
        Cell: (row) => <>{row.value ? "Yes" : "No"}</>
      },      
      {
        Header: "View",
        Cell: (tableProps) => <a href={`/manufacturers/${tableProps.row.original.key}`} className="btn btn-primary btn-sm">View</a>
      },
    ],
    []
  );
  return (
    <React.Fragment>
      <RecordCount record_count={data.length} record_type="manufacturer" />
      { data.length > 0 ? <CustomTable columns={columns} data={data} /> : null }
    </React.Fragment>
  );
};

export default Manufacturers;
