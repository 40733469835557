import React from "react";
import { useState, useMemo } from "react";
import CustomTable from "../CustomTable";

const SuspendedEntitiesReport = (props) => {
  const [data, setData] = useState(
    useMemo(
      () =>
        props.suspensions.map((obj) => {
          return {
            ...obj,
          };
        }),
      []
    )
  );

  const columns = useMemo(
    () => [
      {
        Header: "Entity",
        accessor: "entity_name",
        filter: "fuzzyText",
        Cell: (tableProps) => <b>{tableProps.row.original.entity_name}</b>,
      },
      {
        Header: "340B ID",
        accessor: "id_340b",
        filter: "fuzzyText",
        Cell: (tableProps) => <small className="text-muted">{tableProps.row.original.id_340b}</small>,
      },
      {
        Header: "Pharmacy",
        accessor: "pharmacy_name",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <>
            <b>{tableProps.row.original.pharmacy_name}</b>
            <div>
              <small className="text-muted">{tableProps.row.original.pharmacy_address}</small>
              {
                tableProps.row.original.chain_name ?
                <div> 
                  <small className="text-muted">Chain Name: {tableProps.row.original.chain_name}</small> 
                </div>
                : null
              }
            </div>
          </>
        ),
      },
      {
        Header: "PID",
        accessor: "pharmacy_id",
        filter: "fuzzyText",
        Cell: (tableProps) => <small className="text-muted">{tableProps.row.original.pharmacy_id}</small>,
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer",
        filter: "fuzzyText",
        Cell: (tableProps) => <div className="text-muted">{tableProps.row.original.manufacturer}</div>,
      },
      {
        Header: "Network",
        accessor: "network",
        filter: "fuzzyText",
        Cell: (tableProps) => <div className="badge bg-secondary">{tableProps.row.original.network}</div>,
      },
      {
        Header: "Suspended On",
        accessor: "suspended_on",
        filter: "fuzzyText",
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <CustomTable columns={columns} data={data} />
    </React.Fragment>
  );
};

export default SuspendedEntitiesReport;
