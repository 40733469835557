import React from "react";
import { useState } from "react";
import FormModal from "../FormModal";
import { Form } from "react-bootstrap";

export default function AssignEntityToUserButton(props) {
  const [showAssignmentModal, setShowAssignmentModal] = useState(false);

  return (
    <>
    <button onClick={() => setShowAssignmentModal(true)} className="btn btn-danger">
      Assign Entity
    </button>
    <FormModal
      show={showAssignmentModal}
      title={`Assign ${props.id_340b} to ${props.email} Organization`}
      buttonText="Assign Entity"
      buttonType="danger"
      modalId="assign_entity_to_user_modal"
      method="POST"
      fields={
        <React.Fragment>
          Are you sure you wish to assign {props.id_340b} to {props.email} organization?
          <Form.Control required name="email" type="hidden" value={props.email} />
          <Form.Control required name="id_340b" type="hidden" value={props.id_340b} />
        </React.Fragment>
      }
      action={props.assignment_path}
      dismiss={() => {
        setShowAssignmentModal(false);
      }}
    />    
</>    
  )
}

