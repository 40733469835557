import React from "react";
import { Form } from "react-bootstrap";

export default function NewParentSiteForm(props) {
  return (
    <React.Fragment>
           <Form.Group className="mb-3" controlId="current_parent_site_id_340b">
        <Form.Label>
          340B ID
          {props.current_parent_site ? (
            <small className="text-success ms-1">{props.current_parent_site.id_340b}</small>
          ) : (
            <small className="text-danger ms-1">Not found</small>
          )}
        </Form.Label>
      </Form.Group>
      <Form.Group className="mb-3" controlId="new_parent_site_id_340b">
        <Form.Label>New Parent Site</Form.Label>
        <Form.Select required name="new_parent_site_id_340b">
          <option value="">{"< Make a Selection >"}</option>
          {props.child_sites.map((ce) => (
            <option key={ce.id} value={ce.id_340b}>
              {ce.id_340b}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
    </React.Fragment>
  );
}
