export const numberWithCommas = (val = 0) => {
    return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(val);
  };
  
  export const wholeNumberWithCommas = (val = 0) => {
    return new Intl.NumberFormat("en-US", { maximumFractionDigits: 0 }).format(val);
  };
  
  export const currencyWithCommas = (amount) => {
    return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD", maximumFractionDigits: 0 }).format(
      amount
    );
  };
  