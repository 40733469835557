import React from "react";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

export default function WhollyOwnedCPPidForm(props) {
  const [search340bId, set340bId] = useState(props.id_340b);
  const [searchPid, setSearchPid] = useState(props.hrsa_id);
  const [pharmacyId, setPharmacyId] = useState("");
  const [coveredEntity, setCoveredEntity] = useState("");

  useEffect(() => {
    if (search340bId || searchPid.length > 2) {
      fetch(`/api/form_validations/id_340b_and_pharmacy_id?pharmacy_id=${searchPid}&id_340b=${search340bId}`)
        .then((res) => res.json())
        .then((data) => {
          if (data.response_code == "201") {
            // Set pharmacy if found.
            if (data.pharmacy_id) {
              setPharmacyId(data.pharmacy_id);
            } else {
              // Clear HIN and pharmacy if no pharmacy is found.
              setPharmacyId(null);
            }
          } else {
            // Clear all state fields if there is no matching covered entity.
            setPharmacyId(null);
          }
        });
    }
  }, [searchPid]);

  const handleOnChange = (e) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <Form.Group className="mb-0" controlId="id">
        <Form.Control type="hidden" name="app[id]" value={props.app_id} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="id_340b">
        <Form.Label>Covered Entity 340B ID</Form.Label>
        <Form.Control disabled defaultValue={props.id_340b} />
      </Form.Group>

      <Form.Group className="mb-3" controlId="hrsa_id">
        <Form.Label>
          HRSA Pharmacy ID
          {pharmacyId ? (
            <small>
              <i className="fa fa-check text-success ms-1"></i>
            </small>
          ) : (
            <small className="text-danger ms-1">Not found</small>
          )}
        </Form.Label>
        <Form.Control
          required
          defaultValue={searchPid}
          type="text"
          name="wholly_owned_contract_pharmacy[hrsa_id]"
          placeholder="Enter HRSA Pharmacy ID..."
          onBlur={(e) => {
            setSearchPid(e.target.value);
            handleOnChange(e);
          }}
        />
      </Form.Group>
    </React.Fragment>
  );
}
