import React from "react";
import { useState, useMemo } from "react";
import CustomTable from "./CustomTable";
import activationStateBadgeClass from "./Users/activationStateBadgeClass";
import Modal from "./Modal";
import RecordCount from "./utils/RecordCount";

const Tpas = (props) => {
  const [data, setData] = useState(useMemo(() => props.tpas, []));
  const [entitiesTitle, setEntitiesTitle] = useState(null);
  const [userEntities, setUserEntities] = useState([]);
  const [showEntitiesModal, setShowEntitiesModal] = useState(null);

  function viewEntities(row) {
    setEntitiesTitle(row.company_name + " Entities");
    setUserEntities(row.entities);
    setShowEntitiesModal(true);
  }

  function EntitiesModal(entities) {
    return (
      <ul className="list-group">
        {entities.constructor == Array && entities.length != 0 ? (
          <CustomTable data={entities} columns={entity_columns} />
        ) : (
          <i className="text-muted">
            <br />
            This user has no entities.
          </i>
        )}
      </ul>
    );
  }

  const entity_columns = useMemo(() => [
    {
      Header: "340B ID",
      accessor: "id_340b",
      filter: "fuzzyText",
      Cell: (row) => (
        <a className="badge bg-light text-dark fw-normal text-uppercase" href={`/entities/340bid/${row.value}`}>
          {row.value}
        </a>
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      filter: "fuzzyText",
      Cell: (row) => (
        <a href={`/entities/340bid/${row.id_340b}`} className="text-dark fw-bold">
          {row.value}
        </a>
      ),
    },
    {
      Header: "Action",
      filter: "fuzzyText",
      Cell: (row) => (
        <a className="btn btn-primary btn-sm" href={`/entities/340bid/${row.id_340b}`}>
          View
        </a>
      ),
    },
  ]);
  

  const columns = useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "company_name",
        filter: "fuzzyText",
      },
      {
        Header: "340B IDs",
        accessor: "entity_ids",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <small>
            {tableProps.row.original.entities.length > 1 ? (
              <>
                <a href={`/entities/340bid/${tableProps.row.original.entities[0].id_340b}`}>
                  {tableProps.row.original.entities[0].id_340b}
                </a>
                <a
                  href="#"
                  onClick={() => viewEntities(tableProps.row.original)}
                  className="badge bg-primary text-light ms-2 fw-normal">
                  +{tableProps.row.original.entities.length - 1} more
                </a>
              </>
            ) : tableProps.row.original.entities.length == 1 ? (
              <a href={`/entities/340bid/${tableProps.row.original.entity_ids}`}>
                {tableProps.row.original.entity_ids}
              </a>
            ) : "none"}
          </small>
        ),

      },
      {
        Header: "Status",
        accessor: "status",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <div
            className={
              "badge text-uppercase fw-normal bg-" + activationStateBadgeClass(tableProps.row.original.status)
            }>
            {tableProps.row.original.status}
          </div>
        )
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filter: "fuzzyText",
        Cell: (row) => <small className="text-muted">{row.value}</small>
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <div className="pt-3 pb-2">
        <h1 className="h2">
          <i className="fa fa-building-circle-arrow-right pe-2"></i>
          TPAs
        </h1>
      </div>
      <p className="text-muted">Third party administrators that submit 340B claims on behalf of covered entities.</p>
      <RecordCount record_count={data.length} record_type="TPA" />
      <CustomTable columns={columns} data={data} />
      {showEntitiesModal ? 
        <Modal
          show={showEntitiesModal}
          title={entitiesTitle}
          modalId="entities_modal"
          body={EntitiesModal(userEntities)}
          buttonText="Ok"
          modalSize="large"
          buttonType="primary"
          action={() => {
            setShowEntitiesModal(false);
          }}
          dismiss={() => {
            setShowEntitiesModal(false);
          }}
        />
      : null}
    </React.Fragment>
  );
};

export default Tpas;
