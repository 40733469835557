import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";

export default function FormWizardModal(props) {
  const [step, setStep] = useState(0);
  const maxStep = props.steps.length - 1;

  const handleNextStep = () => {
    if (step < maxStep) {
      setStep(step + 1);
    }
  };

  const handlePrevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  useEffect(() => {
    if (props.show) {
      var modalElement = document.getElementById(props.modalId);
      var modalObject = new bootstrap.Modal(modalElement, {
        keyboard: false,
        backdrop: "static",
      });
      modalObject.show();
    } else {
      if (modalObject) {
        modalObject.hide();
        modalObject.dispose();
      }
    }
  }, [props.show]);

  if (props.show) {
    return (
      <div className="modal fade" role="dialog" id={props.modalId}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.title}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => props.dismiss()}></button>
            </div>
            <Form method={props.method} action={props.action}>
              <div className="modal-body">
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
                />
                {props.steps[step].fields}
              </div>
              <div className="modal-footer">
                {step > 0 && (
                  <Button variant="secondary" type="button" onClick={handlePrevStep}>
                    Previous
                  </Button>
                )}
                {step < maxStep ? (
                  <Button variant="primary" type="button" onClick={handleNextStep}>
                    Next
                  </Button>
                ) : (
                  <Button variant={props.buttonType ?? "primary"} type="submit">
                    {props.buttonText}
                  </Button>
                )}
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => props.dismiss()}>
                  Close
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}
