import React from "react";
import { useState, useMemo, useEffect } from "react";
import CustomTable from "../CustomTable";
import FormModal from "../FormModal";
import { Button, Form } from "react-bootstrap";
import titleCase from "../utils/SnakeToTitleCase";
import Loading from "../utils/Loading";
import RecordCount from "../utils/RecordCount";
import DeleteDesignationFormFields from "./DeleteDesignationFormFields";

const Designations = (props) => {
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [modalEditId, setModalEditId] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalDeleteId, setModalDeleteId] = useState(null);

  const [data, setData] = useState(props.designations || null);
  const [isLoading, setIsLoading] = useState(true);
  const [isExporting, setIsExporting] = useState(false);

  useEffect(() => {
    if (!data && props.designations_path) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.designations_path, { headers: { Accept: "application/json" } });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const fetchedData = await response.json();
          setData(fetchedData);
        } catch (error) {
          console.log("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      if (!data || data.length === 0) {
        fetchData();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Visibility",
        accessor: (str) => "visibility",
        Filter: () => {},
        Cell: (tableProps) => (
          <center>
            {tableProps.row.original.deleted_at ? (
              <i
                title={`Deleted ` + tableProps.row.original.deleted_at}
                className="text-danger fa fa-regular fa-eye-slash"></i>
            ) : tableProps.row.original.assigned.toString().toLowerCase() == "true" ? (
              <i title="Assigned" className="text-success fa fa-solid fa-eye"></i>
            ) : (
              <i title="Not assigned" className="fa fa-solid fa-eye-slash"></i>
            )}
          </center>
        ),
      },
      {
        Header: "340B ID",
        accessor: "id_340b",
        Cell: (tableProps) => (
          <a href={`/entities/340bid/${tableProps.row.original.id_340b}?tab=designations`}>
            {tableProps.row.original.id_340b}
          </a>
        ),
      },
      {
        Header: "PID",
        accessor: "pid",
        Cell: (tableProps) => {
          return (
            <div className={`badge text-uppercase ${tableProps.row.original.pid_check ? "bg-success" : "bg-danger"}`}>{tableProps.row.original.pid}</div>
          );
        },
      },
      {
        Header: "Pharmacy",
        accessor: (row) => `${row.pharmacy_name} ${row.address}`,
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <div className="text-nowrap me-3">
            <b>{tableProps.row.original.pharmacy_name}</b>
            <br />
            <small className="text-muted">{tableProps.row.original.address}</small>
          </div>
        ),
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer_name",
        filter: "fuzzyText",
        Cell: (tableProps) => {
          return (
            <div className="text-nowrap me-3">
              <small>
                {tableProps.row.original.manufacturer_name}
                {tableProps.row.original.is_changeable ? (
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Updates Available"
                    className="text-muted">
                    <i className="fa-solid fa-pencil text-muted ms-2" />
                  </span>
                ) : (
                  <span
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="No Updates Available"
                    className="text-muted">
                    <i className="fa-solid fa-text-slash text-muted" />
                  </span>
                )}
              </small>
            </div>
          );
        },
      },
      {
        Header: "Kind",
        accessor: "kind",
        filter: "fuzzyText",
        Cell: (tableProps) => <div className="badge bg-secondary text-uppercase">{tableProps.row.original.kind}</div>,
      },
      {
        Header: "HIN",
        accessor: "hin",
      },
      {
        Header: "Flag",
        accessor: "decorate_flag",
        Filter: "fuzzyText",
        Cell: (tableProps) => (
          <div className="text-nowrap">
            {tableProps.row.original.decorate_flag ? (
              <small className="text-muted">{tableProps.row.original.decorate_flag}</small>
            ) : (
              <small className="text-muted">
                <i>No flag</i>
              </small>
            )}
            {!tableProps.row.original.deleted_at ? (
              <Button
                className="btn btn-secondary btn-sm bg-light text-dark ms-1"
                onClick={() => {
                  setModalEditId(tableProps.row.original.id);
                  setShowModalEdit(true);
                }}>
                <i className="fa-solid fa-pen"></i>
              </Button>
            ) : null}
          </div>
        ),
      },
      {
        Header: "Permitted Status",
        accessor: "decorate_permitted_status",
        Filter: () => {},
        Cell: (tableProps) => (
          <>
            {tableProps.row.original.decorate_permitted_status ? (
              tableProps.row.original.decorate_permitted_status
            ) : (
              <small className="text-muted">
                <i>No status</i>
              </small>
            )}
          </>
        ),
      },
      {
        Header: "Assigned",
        accessor: "decorate_assigned",
        Filter: () => {},
      },
      {
        Header: "Created At",
        accessor: "created_at",
        Filter: () => {},
        Cell: (tableProps) => <small className="text-muted">{tableProps.row.original.created_at}</small>,
      },
      {
        Header: "Created By",
        accessor: (row) => `${row.user_name} ${row.user_email}`,
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <div>
            {tableProps.row.original.user_deleted_at ? (
              <>
                <div className="text-danger">{tableProps.row.original.user_name}</div>
                <small className="text-danger">{tableProps.row.original.user_email}</small>
              </>
            ) : (
              <>
                <div>{tableProps.row.original.user_name}</div>
                <small className="text-muted">{tableProps.row.original.user_email}</small>
              </>
            )}
          </div>
        ),
      },
      {
        Header: "Completed",
        accessor: "completed_at",
        Filter: () => {},
        Cell: (tableProps) => <small className="text-muted">{tableProps.row.original.completed_at}</small>,
      },
      {
        Header: "Last Updated",
        accessor: "updated_at",
        Filter: () => {},
        Cell: (tableProps) => <small className="text-muted">{tableProps.row.original.updated_at}</small>,
      },
      {
        Header: "Expires",
        accessor: "expires_at",
        Filter: () => {},
        Cell: (tableProps) => <small className="text-muted">{tableProps.row.original.expires_at}</small>,
      },
      {
        Header: "Deletion",
        id: "delete",
        accessor: (str) => "delete",
        Filter: () => {},
        Cell: (tableProps) => (
          <div>
            {!tableProps.row.original.deleted_at ? (
              <>
                {props.can_edit ? (
                  <Button
                    className="btn btn-danger btn-sm"
                    onClick={() => {
                      setModalDeleteId(tableProps.row.original.id);
                      setShowModalDelete(true);
                    }}>
                    <i className="fa-solid fa-trash"></i>
                  </Button>
                ) : (
                  "N/A"
                )}
              </>
            ) : (
              <small className="text-danger">{tableProps.row.original.deleted_at}</small>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const DesignationModal = (props) => {
    return (
      <React.Fragment>
        <Form.Group controlId="flag">
          <Form.Label>Designation Flag</Form.Label>
          <Form.Select as="select" name="designation[flag]" defaultValue={props.data.flag}>
            <option value="">No Flag</option>
            {props.flag_options.map((flag) => (
              <option key={flag} value={flag}>
                {titleCase(flag)}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </React.Fragment>
    );
  };

  const ExportButton = () => {
    if (props.can_export && data.length > 0 && props.designations_export_results_path) {
      return(
        <div className="d-flex">
          <button className="btn btn-success mb-3 ml-auto" onClick={exportResults}>
          { isExporting ? "Exporting..." : "Export Results" }
          </button>
        </div>
      );
    }
  }

  const exportResults = async () => {
    if(!isExporting) {
      setIsExporting(true);
      try {
        const response = await fetch(props.designations_export_results_path, { method: 'get', dataType: 'xlsx', responseType: 'arraybuffer' });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        } else {
          const fetchedData = await response.arrayBuffer();
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([fetchedData], { type: fileType });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          document.body.appendChild(link);
          setTimeout(() => {
            if (document.body.contains(link)) {
              link.href = url;
              link.setAttribute('download', props.designations_export_filename);
              link.click();
              document.body.removeChild(link);
            }
          }, 0);
        }
      } catch (error) {
        console.log("Error fetching data: ", error);
      } finally {
        setIsExporting(false);
      }
    }
  };



  return (
    <div>
      <>
        {isLoading ? (
          <Loading />
        ) : data ? (
          <>
            {!props.hide_count ? <RecordCount record_count={data.length} record_type="designation" /> : null}
            <ExportButton props={props} />
            {data.length > 0 ? <CustomTable columns={props.columns || columns} data={data} /> : null}
            <FormModal
              show={showModalEdit}
              title={`Update Flag for Designation ${data.find((row) => row.id == modalEditId)?.id_340b}, PID: ${
                data.find((row) => row.id == modalEditId)?.pid
              }`}
              buttonText="Submit"
              buttonType="primary"
              modalId={`edit_designation_modal_${modalEditId}`}
              method="POST"
              fields={
                <DesignationModal data={data.find((row) => row.id == modalEditId)} flag_options={props.flag_options} />
              }
              action={`/designations/edit_flag/${modalEditId}`}
              dismiss={() => {
                setShowModalEdit(false);
              }}
            />
            <FormModal
              show={showModalDelete}
              title="Delete Designation"
              buttonText="Delete"
              buttonType="danger"
              modalId={`delete_designation_${modalDeleteId}`}
              fields={
                <DeleteDesignationFormFields
                  id_340b={data.find((row) => row.id == modalDeleteId)?.id_340b}
                  pid={data.find((row) => row.id == modalDeleteId)?.pid}
                  manufacturer_name={data.find((row) => row.id == modalDeleteId)?.manufacturer_name}
                />
              }
              action={`/designations/${modalDeleteId}`}
              method="POST"
              dismiss={() => {
                setShowModalDelete(false);
              }}
            />
          </>
        ) : null}
      </>
    </div>
  );
};

export default Designations;
