import React from "react";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import titleCase from "../utils/SnakeToTitleCase";
import JiraTicket from "../JiraTicket";

export default function NewBlockedForm(props) {
  const [networkOptions, setNetworkOptions] = useState([]);

  return (
    <React.Fragment>
      <Form.Group className="mb-3" controlId="manufacturer">
        <Form.Label>Manufacturer</Form.Label>
        <Form.Select
          required
          name="blocked[manufacturer]"
          onChange={(e) => {
            const man_network = props.manufacturer_networks.find(
              (mn) => mn.manufacturer == e.target.value,
            );
            setNetworkOptions(man_network?.networks || []);
          }}
        >
          <option value="">{"< Make a Selection >"}</option>
          {props.manufacturers.map((manufacturer) => (
            <option key={manufacturer.key} value={manufacturer.key}>
              {manufacturer.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>

      <Form.Group className="mb-3" controlId="network">
        <Form.Label>Network</Form.Label>
        <Form.Control required name="blocked[network]" placeholder="Enter Network..." />
      </Form.Group>
      <Form.Group className="mb-3" controlId="kind">
        <Form.Label>Kind</Form.Label>
        <Form.Select name="blocked[kind]" aria-label="Kind">
          <option value="entity">Entity</option>
          <option value="pharmacy">Pharmacy</option>
          <option value="type_code">Type Code</option>
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="value">
        <Form.Label>Value</Form.Label>
        <Form.Control required name="blocked[value]" placeholder="Enter 340B ID or Type Code..." />
      </Form.Group>
      <JiraTicket />
    </React.Fragment>
  );
}
