import React from "react";
import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import titleCase from "../../utils/SnakeToTitleCase";

export default function WhollyOwnedContractPharmacyApprovals(props) {
  function ApprovalButton(props) {
    if (props.status == "approved") {
      return (
        <Button key={props.key} className="btn btn-secondary btn-sm me-1 disabled mb-1">
          {titleCase(props.network)} Approved
        </Button>
      );
    } else if (props.status == "pending") {
      return (
        <Button
          key={props.key}
          className="btn btn-primary btn-sm me-1 mb-1"
          onClick={() => {
            submit_approval(props.wocp_id, props.application_id, props.network, "approved");
          }}>
          <div
            className="spinner-border spinner-border-sm me-1 visually-hidden"
            id={`${props.network}_loading_${props.wocp_id}`}
            role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
          Approve for {titleCase(props.network)}
        </Button>
      );
    } else if (props.status == "disabled") {
      return (
        <OverlayTrigger
          delay={{ hide: 450, show: 300 }}
          overlay={(props) => (
            <Tooltip {...props}>{titleCase(props.network)} is not available for this pharmacy.</Tooltip>
          )}
          placement="top">
          <Button key={props.key} className="btn btn-secondary btn-sm me-1 disabled mb-1">
            {titleCase(props.network)} N/A
          </Button>
        </OverlayTrigger>
      );
    }
  }

  const submit_approval = (wocp_id, application_id, network, status) => {
    var element = document.getElementById(`${network}_loading_${props.wocp_id}`);
    element.classList.remove("visually-hidden");
    fetch(`/wholly_owned_contract_pharmacies/${wocp_id}/update_status`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: JSON.stringify({
        application_id: application_id,
        network: network,
        status: status,
      }),
    }).then((res) => {
      window.location.reload();
    });
  };

  if (props.contracted_pharmacy) {
    return (
      <>
        {props.network_statuses.map((status_obj) => (
          <ApprovalButton
            key={props.network}
            wocp_id={props.wocp_id}
            application_id={props.application_id}
            network={status_obj.network}
            status={status_obj.status}
          />
        ))}
      </>
    );
  } else {
    return (
      <small className="text-muted">
        <i>Pending valid pharmacy</i>
      </small>
    );
  }
}
