import React from "react";
import titleCase from "../../utils/SnakeToTitleCase";

export default function statusBadgeClass(status) {
    switch(titleCase(status)) {
      case "Complete":
        return "success"
      case "In Review":
        return "warning"
      case "Needs Outreach":
        return "danger"
      default:
        return "secondary"
    }
  }