import React from "react";
import JiraTicket from "../JiraTicket";

export default function DeleteBlockedFormFields(props) {
  return (
    <React.Fragment>
      <p>{`Are you sure you want to delete ${props.network}, ${props.kind_name}, ${props.value}?`}</p>
      <JiraTicket />
      <input type="hidden" value="delete" name="_method"></input>
    </React.Fragment>
  );
};
