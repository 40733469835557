export default function parseAndFormatDate(inputDate) {
  if (inputDate == null) {
    return ''
  }

  const date = new Date(standardizedInputDate(inputDate));

  const year = date.getFullYear();
  const month = date.getMonth() + 1; // Adding 1 because months are zero-based
  const day = date.getDate();

  const formattedDate = `${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}-${year}`;

  return formattedDate;
};

const standardizedInputDate = (inputDate) => {
  if (inputDate.includes('T')) {
    return inputDate
  } else {
    // Dates without timestamps should be formatted as yyyy/mm/dd, not
    //   yyyy-mm-dd, due to how Javascript interprets the timezone
    return inputDate.replaceAll('-','/');
  }
}
