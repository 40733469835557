import React from "react";
import { useTable, usePagination, useFilters } from "react-table";
import { matchSorter } from "match-sorter";
import { Pagination, Form } from "react-bootstrap";
import _uniqueId from "lodash/uniqueId";

function DefaultColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }) {
  const count = preFilteredRows.length;

  return (
    <input
      className="form-control form-control-sm"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Filter ${count} records...`}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const CustomTable = ({ columns, data, page_size, hide_page_size_if_unneeded }) => {
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: page_size || 50 },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    usePagination,
  );

  function toggleFilter(id) {
    var filter = document.getElementById(`${id}_filter`);
    var filter_toggle = document.getElementById(`${id}_filter_link`);
    if (filter.classList.contains("visually-hidden")) {
      filter.classList.remove("visually-hidden");
      filter_toggle.classList.add("visually-hidden");
      var input_element = filter.getElementsByClassName("form-control")[0];
      input_element.focus();
    } else {
      filter.classList.add("visually-hidden");
      filter_toggle.classList.remove("visually-hidden");
      var input_element = filter.getElementsByClassName("form-control")[0];
      var nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value",
      ).set;
      nativeInputValueSetter.call(input_element, "");
      var ev2 = new Event("input", { bubbles: true });
      input_element.dispatchEvent(ev2);
    }
  }

  function columnFilter(column) {
    const id = _uniqueId(`${column.id}_`);
    if (column.canFilter && column.Filter.name != "Filter") {
      return (
        <>
          <a id={`${id}_filter_link`} onClick={() => toggleFilter(id)} className="text-muted">
            <i className="fa fa-search ms-1"></i>
          </a>
          <div id={`${id}_filter`} className="visually-hidden columnFilter">
            {column.render("Filter")}
            <a onClick={() => toggleFilter(id)}>
              <i className="fa fa-close"></i>
            </a>
          </div>
        </>
      );
    }
  }

  function showPageSizeSelector() {
    // Never render selector if all results fit on smallest page
    if (data.length < 10) return false;
    // Always render selector if all results exceed current page size
    if (data.length > pageSize) return true;
    // Use toggle to hide/show selector
    if (hide_page_size_if_unneeded) return false;
    // Default to true if the above checks do not pass.
    return true;
  }

  return (
    <React.Fragment>
      <div
        className="customTableContainer bg-white rounded border mb-3 shadow-sm inset"
        style={{ overflow: "scroll", width: "100%" }}
      >
        <table className="table table-striped mb-0" {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th scope="col" {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {columnFilter(column)}
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {page.length == 0
              ? headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    <td colSpan={headerGroup.headers.length}>
                      <small className="text-muted">
                        <i>No Data</i>
                      </small>
                    </td>
                  </tr>
                ))
              : // Loop over the table rows
                page.map((row, i) => {
                  // Prepare the row for display
                  prepareRow(row);
                  return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                      {
                        // Loop over the rows cells
                        row.cells.map((cell) => {
                          // Apply the cell props
                          return (
                            <td {...cell.getCellProps()}>
                              {
                                // Render the cell contents
                                cell.render("Cell")
                              }
                            </td>
                          );
                        })
                      }
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
      <div className="pagination row">
        <div className="col-md-8">
          {canPreviousPage || canNextPage ? (
            <Pagination>
              <Pagination.First onClick={() => gotoPage(0)} disabled={!canPreviousPage} />
              <Pagination.Prev onClick={() => previousPage()} disabled={!canPreviousPage} />
              <Pagination.Item
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                hidden={pageIndex < 3}
              >
                {1}
              </Pagination.Item>
              <Pagination.Ellipsis disabled hidden={pageIndex < 4} />
              <Pagination.Item onClick={() => gotoPage(pageIndex - 2)} hidden={pageIndex < 2}>
                {pageIndex - 1}
              </Pagination.Item>
              <Pagination.Item onClick={() => gotoPage(pageIndex - 1)} hidden={pageIndex < 1}>
                {pageIndex}
              </Pagination.Item>
              <Pagination.Item disabled>{pageIndex + 1}</Pagination.Item>
              <Pagination.Item
                onClick={() => gotoPage(pageIndex + 1)}
                hidden={pageIndex + 2 > pageCount}
              >
                {pageIndex + 2}
              </Pagination.Item>
              <Pagination.Item
                onClick={() => gotoPage(pageIndex + 2)}
                hidden={pageIndex + 3 > pageCount}
              >
                {pageIndex + 3}
              </Pagination.Item>
              <Pagination.Ellipsis disabled hidden={pageIndex + 5 > pageCount} />
              <Pagination.Item
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                hidden={pageIndex + 4 > pageCount}
              >
                {pageCount}
              </Pagination.Item>
              <Pagination.Next onClick={() => nextPage()} disabled={!canNextPage} />
              <Pagination.Last onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} />
            </Pagination>
          ) : null}
        </div>
        <div className="col-md-4">
          {showPageSizeSelector() ? (
            <Form.Select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </Form.Select>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomTable;
