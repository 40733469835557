import React from "react";
import JiraTicket from "../JiraTicket";

export default function DeleteEffectiveDateFormFields(props) {
  return (
    <React.Fragment>
      <p>{`Are you sure you want to delete ${props.covered_entity}'s PID: ${props.pid}, ${props.manufacturer_name} effective date?`}</p>
      <JiraTicket />
      <input type="hidden" value="delete" name="_method"></input>
    </React.Fragment>
  );
}
