import React from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";

export default function ExportAllDesignationsButton(props) {
  const [isLoading, setIsLoading] = useState(null);

  const fetchData = async () => {
    if(!isLoading) {
      setIsLoading(true);
      try {
        const response = await fetch(props.export_designations_path, { method: 'get', dataType: 'xlsx', responseType: 'arraybuffer' });
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        } else {
          const fetchedData = await response.arrayBuffer();
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([fetchedData], { type: fileType });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          document.body.appendChild(link);
          setTimeout(() => {
            if (document.body.contains(link)) {
              link.href = url;
              link.setAttribute('download', props.filename);
              link.click();
              document.body.removeChild(link);
            }
          }, 0);
        }
      } catch (error) {
        console.log("Error fetching data: ", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <div>
      <a onClick={() => fetchData()} className="sidebar-link">
        <i className="fa fa-download me-2"></i>
        { isLoading ? "Exporting..." : "Export All" }
      </a>
    </div>
  )
}
