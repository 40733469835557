import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";

export default function EligibilityStatusDetails(props) {
  const renderEligibleBadge = (status, network) => {
    return <div className="badge bg-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Eligible">{network}</div>;
  };

  const renderAtRiskBadge = (status, network) => {
    return <div className="badge bg-warning text-dark" data-bs-toggle="tooltip" data-bs-placement="top" title="At Risk">{network}</div>;
  };

  const renderIneligibleBadge = (status, network) => {
    return <div className="badge bg-danger" data-bs-toggle="tooltip" data-bs-placement="top" title="Ineligible">{network}</div>;
  };

  const renderStatusBadge = (status, network) => {
    switch (status) {
      case "ELIGIBLE":
        return renderEligibleBadge(status, network);
      case "IN_PROCESS":
        return renderEligibleBadge(status, network);
      case "AT_RISK":
        return renderAtRiskBadge(status, network);
      case "INELIGIBLE_MISSING_CLAIMS":
        return renderIneligibleBadge(status, network);
      case "INELIGIBLE":
        return renderIneligibleBadge(status, network);
    }
  };

  const renderStatus = () => {
    // Group items by key
    const groupedItems = _.reduce(
      props.eligibility,
      (result, val, key) => {
        _.forEach(val[props.row].networks, (network) => {
          if (!result[key]) {
            result[key] = [];
          }
          result[key].push(network);
        });
        return result;
      },
      {}
    );

    // Render each group with items displayed next to the key
    return _.map(groupedItems, (networks, key) => (
      <tr className="group" key={key}>
        <td style={{ display: "flex", flexWrap: "wrap" }}>
          <div className="key t--md" style={{ paddingRight: "5px" }}>
            {key}
          </div>
        </td>
        <td>
          <div className="items">
            {networks.map((network) => (
              <span className="network-badge" key={network} style={{ paddingRight: "5px" }}>
                {renderStatusBadge(props.eligibility[key][props.row][network], network)}
              </span>
            ))}
            </div>
        </td>
      </tr>
    ));
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <small className="text-muted me-1">Product Status Colors:</small>
          <div className="badge bg-warning text-dark me-1">At Risk</div>
          <div className="badge bg-success me-1">Eligible</div>
          <div className="badge bg-danger me-1">Ineligible</div>
          <hr />
          <table className="status-container table">
            <thead>
              <tr>
                <td>Manufacturer</td>
                <td>Products / Status</td>
              </tr>
            </thead>
            {renderStatus()}
          </table>
        </div>
      </div>
    </div>
  );
}
