import React from "react";
import { useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import FormModal from "../FormModal";
import { Button, Form } from "react-bootstrap";
import RecordCount from "../utils/RecordCount";
import DeleteBlockedFormFields from "./DeleteBlockedFormFields";

const BlockListEntity = (props) => {
  const [data, setData] = useState(
    useMemo(
      () =>
        props.blocked.map((obj) => ({
          ...obj,
          kind_name: obj.kind == 0 ? "id_340b" : "prefix",
        })),
      []
    )
  );
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalDeleteId, setModalDeleteId] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Kind",
        accessor: "kind",
        filter: "fuzzyText",
      },
      {
        Header: "340B ID",
        accessor: "value",
        filter: "fuzzyText",
        Cell: (row) => <a href={`/entities/340bid/${row.value}`}>{row.value}</a>,
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer",
        filter: "fuzzyText",
        Cell: (row) => <small>{row.value || "none"}</small>
      },
      {
        Header: "Network",
        accessor: "network",
        filter: "fuzzyText",
      },
      {
        Header: "Actions",
        id: "id",
        accessor: (str) => "delete",
        Filter: () => {},
        Cell: (tableProps) => (
          <>
          { props.can_edit ?
            <Button
              className="btn btn-danger btn-sm"
              onClick={() => {
                setShowModalDelete(true);
                setModalDeleteId(tableProps.row.original.id);
              }}>
              <i className="fa fa-trash"></i>
            </Button>
          : "N/A" }
          </>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
      { !props.hide_count ?
        <RecordCount record_count={data.length} />
      : null }
      { data.length > 0 || props.hide_count ? <CustomTable columns={columns} data={data} /> : null }
      <FormModal
        show={showModalDelete}
        title="Delete Blocked"
        buttonText="Delete"
        buttonType="danger"
        modalId={`delete_blocked_${modalDeleteId}`}
        fields={
          <DeleteBlockedFormFields
            network={data.find((row) => row.id == modalDeleteId)?.network}
            kind_name={data.find((row) => row.id == modalDeleteId)?.kind_name}
            value={data.find((row) => row.id == modalDeleteId)?.value}
          />
        }
        method="POST"
        action={`/blocked/${data.find((row) => row.id == modalDeleteId)?.id}`}
        dismiss={() => {
          setShowModalDelete(false);
        }}
      />
    </React.Fragment>
  );
};

export default BlockListEntity;
