import React from "react";
import { useState, useEffect } from "react";
import NewParentSiteForm from "./NewParentSiteForm";
import FormModal from '../FormModal'

export default function ChangeParentSiteButton(props) {
  const [showExceptionModal, setShowExceptionModal] = useState(false);

  return (
    <>
    <button onClick={() => setShowExceptionModal(true)} className="btn btn-primary ms-2 btn-sm">
      <i className="fa fa-map-pin me-1"></i>
      Change Parent Site
    </button>
    <FormModal
      show={showExceptionModal}
      title="Change Parent Site"
      buttonText="Update"
      buttonType="primary"
      modalId="change_parent_site_modal"
      method="POST"
      fields={
        <NewParentSiteForm
          child_sites={props.child_sites}
          current_parent_site={props.current_parent_site}
        />
      }
      action={`${props.current_parent_site.id}/update_parent_site_and_designations`}
      dismiss={() => {
        setShowExceptionModal(false);
      }}
    />
</>    
  )
}
