import React from "react";
import { useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import FormModal from "../FormModal";
import Modal from "../Modal";
import { Form } from "react-bootstrap";

const EntityValidations = (props) => {
  const [showModalApply, setShowModalApply] = useState(false);
  const [applyModalId, setApplyModalId] = useState(null);
  const [data, setData] = useState(
    useMemo(() =>
      props.entity_validations.map(
        (obj) => ({
          ...obj
        }),
        []
      )
    )
  );
  const [addValidationModal, setAddValidationModal] = useState(false);

  const validationColumns = useMemo(
    () => [
      { Header: "Email", accessor: "email", Cell: row => <small className="text-muted">{row.value}</small> },
      { Header: "Email Domain", accessor: "email_domain", Cell: row => row.value ? <b>{row.value}</b> : <small className="text-muted">N/A</small> },
      { Header: "Created At", accessor: "created_at", Cell: row => <small className="text-muted">{row.value}</small>},
      { Header: "Actions", Cell: tableProps => (
        <>
          { !tableProps.row.original.is_user_valid ? 
            <small className="text-muted">User not found</small> 
            : tableProps.row.original.is_user_valid && tableProps.row.original.user_state === 'auto-rejected' ? 
          <a className="btn btn-primary btn-sm" 
              onClick={() => {
              setShowModalApply(true);
              setApplyModalId(tableProps.row.original.id);
              }}
            >
            Send Email
            </a>
            : <small> Email Sent </small> 
          }
        </>
      )
      }
    ],
    []
  );
  const CreateEntityValidationFormFields = () => {
    return (
      <React.Fragment>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control required name="entity_validation[email]" placeholder="Enter email..." />
        </Form.Group>
        <Form.Group className="mb-3" controlId="id_340b">
          <Form.Label>340B ID</Form.Label>
          <Form.Control readOnly required name="entity_validation[id_340b]" defaultValue={props.entity.id_340b} />
        </Form.Group>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="mb-3">
        <small className="text-muted">Want to add an entity validation?</small>
        <button
          onClick={() => {
            setAddValidationModal(true);
          }}
          className="btn btn-primary btn-sm ms-2">
          <i className="fa fa-clipboard-check me-1"></i>
          Create Entity Validation
        </button>
      </div>
      <CustomTable columns={validationColumns} data={data} entity={props.entity} />
      <FormModal
        show={addValidationModal}
        title="Add Entity Validation for Covered Entity"
        buttonText="Submit"
        buttonType="primary"
        modalId="add_validation_modal"
        fields={<CreateEntityValidationFormFields props={data} />}
        action={`/entities/${props.entity.id}/create_entity_validation`}
        method="POST"
        dismiss={() => {
          setAddValidationModal(false);
        }}
      />
      <FormModal
        show={showModalApply}
        title="Manual User Approval"
        buttonText="Send"
        buttonType="primary"
        modalId={`apply_modal_${applyModalId}`}
        fields={
          <>
            <p>
              This will send an email to <b>{data.find((row) => row.id == applyModalId)?.email}</b> to notify them to complete their registration.
            </p>
          </>
        }
        method="POST"
        action={`/entity_validations/${data.find((row) => row.id == applyModalId)?.id}/send_manual_approval_email`}
        dismiss={() => {
          setShowModalApply(false);
        }}
      />     
    </React.Fragment>
  );
};

export default EntityValidations;
