import React from "react";
import { useMemo } from "react";
import CustomTable from "./CustomTable";
import activationStateBadgeClass from "./Users/activationStateBadgeClass";
import { Tab, Tabs, Nav, Row } from "react-bootstrap";

const Organization = (props) => {

  const entity_columns = useMemo(
    () => [
      {
        Header: "340B ID",
        accessor: "id_340b",
        filter: "fuzzyText",
        Cell: tableProps => <a href={`/entities/${tableProps.row.original.id}`}>{tableProps.value}</a>
      },
      {
        Header: "Covered Entity",
        accessor: (row) => `${row.entity_name} ${row.address1} ${row.city}, ${row.state}, ${row.designation_permitted_status}`,
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <>
            <b>{tableProps.row.original.entity_name}</b>
            <br/>
            <small className="text-muted">
              {tableProps.row.original.address1}, {tableProps.row.original.city}, {tableProps.row.original.state}
            </small>
          </>
        )
      },
    ],
    []
  );  
  const user_columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: (row) => `${row.full_name} ${row.email}`,
        filter: "fuzzyText",
        Cell: tableProps => <div className="text-nowrap">
          {tableProps.row.original.full_name}
          <br />
          <small className="text-muted">{tableProps.row.original.email}</small>
        </div>
      },
      {
        Header: 'Status',
        accessor: 'activation_state',
        filter: "fuzzyText",
        Cell: tableProps =>
        <div className={"badge text-uppercase fw-normal bg-" + activationStateBadgeClass(tableProps.row.original.activation_state)}>
          {tableProps.row.original.activation_state}
        </div>
      },
      {
        Header: "Role",
        accessor: "role",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <div className="badge bg-light text-dark text-uppercase fw-normal">
             {tableProps.row.original.role}
          </div>
        )
      }
    ],
    []
  );  

  const alertId = 'review_alert_' + props.organization_id;

  return (
    <React.Fragment>  
      <div className="alert alert-danger" id={alertId}>
        <b>Organization exists.</b> Please review all entities and users before continuing.
      </div>
      <Tabs
      defaultActiveKey="entities"
      variant="pills"
      id="organization_tabs"
      className="mb-3">
        <Tab eventKey="entities" title={<>Entities <span className="badge bg-light text-dark">{props.entities.length}</span></>}>
          <CustomTable data={props.entities} columns={entity_columns} />
        </Tab>
        <Tab eventKey="users" title={<>Users <span className="badge bg-light text-dark">{props.users.length}</span></>}>        
          <CustomTable data={props.users} columns={user_columns} />
        </Tab>
        <Tab eventKey="reviewed" title="I have reviewed all entities/users.">
          <div className="alert alert-success">
            Thank you for carefully reviewing!
          </div>
        </Tab>
        </Tabs>
    </React.Fragment>
  );
};

export default Organization;
