import React from "react";
import { Form } from "react-bootstrap";

export default function InviteUserFormFields(props) {
  return (
    <React.Fragment>
       <p>
        <small className="text-danger">* Required</small>
      </p>
      <Form.Group className="mb-3" controlId="email">
        <Form.Label>Email <small className="text-danger">*</small></Form.Label>
        <Form.Control required name="user[email]" placeholder="Enter email..." />
      </Form.Group>
      <p className="mt-2">
        <small className="text-muted"><b>Note:</b> An entity validation will be created for this email domain, if it does not already exist. Please ensure email is correct.</small>
      </p>
      <Form.Group className="mb-3" controlId="email_confirmation">
        <Form.Label>Confirm Email <small className="text-danger">*</small></Form.Label>
        <Form.Control required name="user[email_confirmation]" placeholder="Confirm email..." />
      </Form.Group>
      <Form.Group className="mb-3" controlId="first_name">
        <Form.Label>First Name <small className="text-danger">*</small></Form.Label>
        <Form.Control required name="user[first_name]" placeholder="Enter first name..." />
      </Form.Group>
      <Form.Group className="mb-3" controlId="last_name">
        <Form.Label>Last Name <small className="text-danger">*</small></Form.Label>
        <Form.Control required name="user[last_name]" placeholder="Enter last name..." />
      </Form.Group>
      <Form.Group className="mb-3" controlId="role">
        <Form.Label>Role <small className="text-danger">*</small></Form.Label>
        <Form.Select required name="user[role]" as="select">
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </Form.Select>
      </Form.Group>
    </React.Fragment>
  );
};