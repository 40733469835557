import React from "react";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import titleCase from "../utils/SnakeToTitleCase";
import JiraTicket from "../JiraTicket.jsx";

export default function NewLdnForm(props) {
  const [pharmacyId, setPharmacyId] = useState(null);
  const [searchPid, setSearchPid] = useState("");
  const [search340bId, set340bId] = useState("");
  const [coveredEntity, setCoveredEntity] = useState("");
  const [networkOptions, setNetworkOptions] = useState([]);

  useEffect(() => {
    if (search340bId.length > 2 || searchPid.length > 2) {
      fetch(
        `/api/form_validations/id_340b_and_pharmacy_id?pharmacy_id=${searchPid}&id_340b=${search340bId}`,
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.response_code == "201") {
            // Set covered entity if found.
            // Set pharmacy if found.
            // Auto fill HIN if pharmacy is found.
            setCoveredEntity(data.covered_entity);
            if (data.pharmacy_id) {
              setPharmacyId(data.pharmacy_id);
            } else {
              // Clear HIN and pharmacy if no pharmacy is found.
              setPharmacyId(null);
            }
          } else {
            if (!data.covered_entity) {
              // Clear all state fields if there is no matching covered entity.
              setCoveredEntity(null);
            }
            setPharmacyId(null);
          }
        });
    }
  }, [searchPid, search340bId]);

  const handleOnChange = (e) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <div className="float-right text-danger mb-3">
        <small>* required</small>
      </div>
      <Form.Group className="mb-3" controlId="manufacturer_name">
        <Form.Label>
          Manufacturer <small className="text-danger">*</small>
        </Form.Label>
        <Form.Select
          required
          name="ldn[manufacturer_name]"
          onChange={(e) => {
            const man_network = props.manufacturer_networks.find(
              (mn) => mn.manufacturer == e.target.value,
            );
            setNetworkOptions(man_network?.networks || []);
          }}
        >
          <option value="">{"< Make a Selection >"}</option>
          {props.manufacturers.map((manufacturer) => (
            <option key={manufacturer.key} value={manufacturer.key}>
              {manufacturer.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="network">
        <Form.Label>
          Network <small className="text-danger">*</small>
        </Form.Label>
        <Form.Select required name="ldn[network]">
          <option value="">{"< Make a Selection >"}</option>
          {networkOptions.map((network) => (
            <option key={network} value={network}>
              {network}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="id_340b">
        <Form.Label>
          340B ID{" "}
          <small>
            <i className="text-muted">(optional)</i>
          </small>
          {coveredEntity ? <small className="text-success ms-1">{coveredEntity}</small> : null}
        </Form.Label>
        <Form.Control
          type="text"
          name="ldn[id_340b]"
          placeholder="Enter 340B ID..."
          onBlur={(e) => {
            set340bId(e.target.value);
            handleOnChange(e);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-4" controlId="pid">
        <Form.Label>
          Pharmacy ID <small className="text-danger">*</small>
          {pharmacyId ? (
            <small>
              <i className="fa fa-check text-success ms-1"></i>
            </small>
          ) : null}
        </Form.Label>
        <Form.Control
          required
          type="text"
          name="ldn[pid]"
          placeholder="Enter PID..."
          onBlur={(e) => {
            setSearchPid(e.target.value);
            handleOnChange(e);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="kind">
        <Form.Check name="ldn[kind]" value="wholly_owned" label="Wholly Owned" />
      </Form.Group>
      <JiraTicket />
    </React.Fragment>
  );
}
