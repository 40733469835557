import React from "react";
import { wholeNumberWithCommas } from "./NumberFormatter";

export default function RecordCount(props) {
  return (
    <div className="jumbotron p-2 mb-3 bg-body-tertiary rounded-4 text-center">
      <small className="text-muted"><b>{wholeNumberWithCommas(props.record_count)}</b> {props.record_type || "record"}(s) found.</small>
    </div>
  )
}
