import React, { useState, useEffect, useRef } from 'react';
import { Spinner } from 'react-bootstrap';

const GlobalSearch = (props) => {
  const [searchType, setSearchType] = useState('entities');
  const [searchText, setSearchText] = useState('');
  const [results, setResults] = useState([]);
  const [prevSearchText, setPrevSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [lastFetchTime, setLastFetchTime] = useState(0);
  const searchRef = useRef();
  const resultsRef = useRef();

  const searchTypes = [
    { value: 'entities', label: 'Entity', icon: "fa-hospital", search_descriptor: "Covered Entity or 340B ID" }
  ];

  const fetchResults = async (force = false) => {
    const currentTime = new Date().getTime();
    if ((searchText.length > 3 && searchText !== prevSearchText && currentTime - lastFetchTime > 2000) || force) {
      setLoading(true);
      try {
        const response = await fetch(`/api/search?search_type=${searchType}&search_text=${searchText}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        // When request is not authenticated (e.g. session timed out), response is okay but data has code 511
        // TODO: When API::ApplicationController#not_authicated is updated to set an error status, we can remove this check
        if (data.code == '511') {
          throw new Error('API request was not authenticated');
        }
        setResults(data);
        setPrevSearchText(searchText);
      } catch (error) {
        console.error('Fetch error:', error);
      } finally {
        setLoading(false);
        setLastFetchTime(currentTime);
      }
    } else if (searchText.length == 0 && results.length > 0) {
      setResults([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchResults();
  }, [searchText]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target) && resultsRef.current && !resultsRef.current.contains(event.target)) {
        setResults([]);
        setLoading(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      fetchResults(true);
    }
  };

  return (
    <div className="input-group input-group-sm rounded-lg" ref={searchRef}>
      <div className="input-group-text text-muted smaller">
        <small className="smaller">
          <i className="fa fa-hospital" />
        </small>
      </div>
      <input type="text" className="form-control" value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyDown={handleKeyDown} placeholder={`${searchTypes.find((type) => type.value === searchType).search_descriptor}...`} />
      <button className="btn border input-group-btn rounded-end" type="button" onClick={() => fetchResults(true)}>
        <i className="fa fa-search"></i>
      </button>
      {loading ? (
        <ul className="list-group shadow-sm mt-2" style={{position: 'absolute', left: 0, top: '100%', zIndex: 1, width: "100%"}} ref={resultsRef}>
          <li className="list-group-item p-3 bg-body-tertiary">
            <center>
              <Spinner animation="border" variant="light" className="text-muted" />
            </center>
          </li>
        </ul>
      ) : (
        <ul className="list-group shadow text-truncate mt-2" style={{position: 'absolute', left: 0, top: '100%', maxHeight: 400, zIndex: 1, overflowY: "auto", width: "100%"}} ref={resultsRef}>
          {results.map((result) => (
            <a href={`/${searchType}/${result.id}`} key={result.id} className="list-group-item list-group-item-action">
              <div className="d-flex text-nowrap">
                {result.pharmacy_id ? <span className='badge bg-light text-muted me-2'><i className="fa fa-prescription-bottle me-2 mb-1"></i>{result.pharmacy_id}</span> : null}
                {result.id_340b ? <span className='badge bg-secondary me-2'><i className="fa fa-hospital me-2"></i>{result.id_340b}</span> : null}
                {searchType == "chains" ? <i className="fa fa-link me-2 text-muted"></i> : null}
                <small>
                  {result.name}
                </small>
              </div>
            </a>
          ))}
        </ul>
      )}
    </div>
  );
};

export default GlobalSearch;
