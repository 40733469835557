import React from "react";
import { useState, useMemo } from "react";
import { Form } from "react-bootstrap";
import FormModal from "../FormModal";
import Modal from "../Modal";
import { Button } from "react-bootstrap";
import NewLdnForm from "./NewLdnForm";
import ImportLdnsForm from "./ImportLdnsForm";

const LdnsHeader = (props) => {
  const [showModalAdd, setShowModalAdd] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  return (
    <React.Fragment>
      <div className="pt-3 pb-2 row">
        <div className="col-9">
          <h1 className="h2">
            <i className="fa fa-network-wired pe-2"></i>
            Limited Distribution Networks (LDNs)
          </h1>
        </div>
        {props.can_edit ? (
          <div className="col-3 text-end">
            <Button className="btn btn-success btn-sm me-2" onClick={() => setShowFileUploadModal(true)}>
              <i className="fa fa-upload me-2"></i>
              Upload From File
            </Button>
            <Button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setShowModalAdd(true);
              }}>
              <i className="fa fa-plus pe-2"></i>
              Add LDN
            </Button>
          </div>
        ) : null}
      </div>
      <p className="text-muted">Scoped 340B pricing access under specified networks / covered entities / pharmacies.</p>
      <Modal
        disableFooter={true}
        show={showFileUploadModal}
        title="Add LDNs From File"
        modalId={`upload_pid_ldn_modal`}
        body={
          <ImportLdnsForm
            manufacturers={props.manufacturers}
            manufacturer_networks={props.manufacturer_networks}
            upload_url={props.upload_url}
          />
        }
        dismiss={() => {
          setShowFileUploadModal(false);
          location.reload();
        }}
      />
      <FormModal
        show={showModalAdd}
        title="Add LDN Item"
        buttonText="Submit"
        buttonType="primary"
        modalId="add_ldn_modal"
        fields={<NewLdnForm manufacturers={props.manufacturers} manufacturer_networks={props.manufacturer_networks} />}
        method="POST"
        action="/ldns"
        dismiss={() => {
          setShowModalAdd(false);
        }}
      />
    </React.Fragment>
  );
};

export default LdnsHeader;
