import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Card, Image } from "react-bootstrap";

import logo from "../../../assets/images/logo.png";

function Register(props) {
  const [formData, setFormData] = useState({
    user: { email: "", password: "", confirmPassword: "", phone: "" },
  });

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showTooltip, setShowTooltip] = useState(false);
  const [paramValue, setParamValue] = useState(""); // State to hold the parameter value
  const PASSWORD_REQUIREMENTS = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;
  const PHONE_NUMBER_PATTERN = /^\d{10}$/; // Adjust the pattern as needed for your specific phone number format

  useEffect(() => {
    // Get the parameter from the URL
    const urlParts = window.location.href.split("/");
    const paramFromUrl = urlParts[urlParts.length - 1];

    // Set the parameter value in state
    setParamValue(paramFromUrl);
  }, []); // Empty dependency array ensures the effect runs only once

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "password" || name === "confirmPassword") {
      const match = formData.password === formData.confirmPassword;
      setPasswordsMatch(match);

      if (!match) {
        setShowTooltip(true);
      } else {
        setShowTooltip(false);
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   // Add your registration logic here, e.g., send data to the server
  // };

  return (
    <Container className="login-container">
      <Row className="justify-content-center align-items-center bg-light">
        <Col md={6} className="p-4">
          <Card className="logo-card">
            <Image
              className="mt-2 ml-3"
              src={logo}
              alt="company-logo"
              style={{ width: "150px", height: "auto" }} // Adjust the width as needed
            />
            <h1 className="font-weight-bold mt-2 mb-2">Support Mgmt Platform</h1>
            <p>Complete onboarding.</p>
            <p className="mt-2 small font-italic" style={{ textAlign: "left" }}>
              <strong>Password Min Requirements:</strong>
              <br />8 characters, 1 number, 1 lowercase, 1 uppercase, 1 symbol
            </p>
          </Card>
        </Col>
        <Col md={6} className="p-4">
          <Card>
            <Card.Body>
              <Form method="POST" action={`/users_mgmt/register?token=${paramValue}`}>
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
                />
                <Form.Group controlId="phoneNumber">
                  <Form.Control
                    type="tel"
                    name="user[phone]"
                    placeholder="Enter a valid 10-digit phone number."
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                    pattern={PHONE_NUMBER_PATTERN.source} // Set the phone number pattern
                  />
                </Form.Group>
                <Form.Group className="mt-2" controlId="password">
                  <Form.Control
                    type="password"
                    name="user[password]"
                    placeholder="Password"
                    value={formData.password}
                    pattern={PASSWORD_REQUIREMENTS.source} // Set pattern attribute
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mt-4" controlId="confirmPassword">
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      name="user[confirmPassword]"
                      placeholder="Confirm Password"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      required
                    />
                    <Button
                      style={{ border: "none", marginLeft: "10px" }}
                      variant="outline-secondary"
                      onClick={handleTogglePasswordVisibility}>
                      {showConfirmPassword ? "Hide" : "Show"}
                    </Button>
                  </div>
                </Form.Group>
                <div className="d-flex justify-content-end">
                  <Button className="mt-2" variant="primary" type="submit">
                    Register
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;
