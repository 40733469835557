import React from "react";
import { useState } from "react";
import NewDesignationForm from "./NewDesignationForm";
import FormModal from "../FormModal";

export default function CreateDesignationSidebarButton(props) {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
    <a onClick={() => setShowModal(true)} className="sidebar-link">
      <i className="fa fa-map-pin me-2"></i>
      Create
    </a>
    <FormModal
      show={showModal}
      title="Create New Designation"
      buttonText="Create Designation"
      buttonType="primary"
      modalId="create_exception_modal"
      method="POST"
      fields={
        <NewDesignationForm
          props={props}
          manufacturers={props.manufacturers || []}
          designation_kinds={props.designation_kinds || []}
        />
      }
      action={props.create_designation_path || "/designations"}
      dismiss={() => {
        setShowModal(false);
      }}
    />
</>
  )
}
