import React from "react";
import { useState } from "react";
import NewDesignationForm from "./NewDesignationForm";
import FormModal from "../FormModal";

export default function CreateDesignationButton(props) {
  const [showExceptionModal, setShowExceptionModal] = useState(false);

  return (
    <>
    <button onClick={() => setShowExceptionModal(true)} className="btn btn-primary ms-2 btn-sm">
      <i className="fa fa-map-pin me-1"></i>
      Create Designation
    </button>
    <FormModal
      show={showExceptionModal}
      title="Create New Designation"
      buttonText="Create Designation"
      buttonType="primary"
      modalId="create_exception_modal"
      method="POST"
      fields={
        <NewDesignationForm
          id_340b={props.id_340b}
          props={props}
          manufacturers={props.manufacturers}
          designation_kinds={props.designation_kinds}
        />
      }
      action={props.create_designation_path}
      dismiss={() => {
        setShowExceptionModal(false);
      }}
    />    
</>    
  )
}

