import React, { useEffect, useState, useMemo } from "react";
import { Button } from "react-bootstrap";
import CustomTable from "./CustomTable";
import Modal from "./Modal";
import Loading from "./utils/Loading";

const Activities = (props) => {
  const [versions, setVersions] = useState(props.activities || null);
  const [showActivityModal, setShowActivityModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [objectChanges, setObjectChanges] = useState(true);
  const [changesTitle, setChangesTitle] = useState(true);

  const activityColumns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "whodunnit",
        Cell: (tableProps) => (
          <>
            {tableProps.row.original.whodunnit == "System" ?
            <div className="text-muted">
              <i className="fa fa-cog me-2"></i>
              System
            </div>
            :
            <div className="text-nowrap">
              <a href={`/user/${tableProps.row.original.user_id}`} target="_blank">
                <i className="fa fa-user me-2"></i>
              </a>
              {tableProps.row.original.whodunnit}
            </div>
            }
          </>
        )
      },
      {
        Header: "When",
        accessor: "when",
        Cell: (tableProps) => (
          <small className="badge bg-secondary">
            {tableProps.row.original.when}
          </small>
        )
      },
      { Header: "Activity", accessor: "event" },
      {
        Header: "Item Type",
        accessor: "item_type",
        Cell: (tableProps) => (
          <>
            {tableProps.row.original.event == "destroy" ?
            <small className="text-danger">
              {tableProps.row.original.item_type}
            </small>
            :
            <small className="text-muted">
              <a href={tableProps.row.original.item_link} target="_blank">{tableProps.row.original.item_type}</a>
            </small>
            }
          </>
        )
      },
      {
        Header: "Identifier",
        accessor: "item_id",
        Cell: (tableProps) => (
          <small className="text-muted">
            {tableProps.row.original.item_id}
          </small>
        )
       },
      {
        Header: "Date",
        accessor: "created_at_date",
        Cell: (tableProps) => (
          <small className="text-muted">
            {tableProps.row.original.created_at_date}
          </small>
        )
       },
      {
        Header: "Time",
        accessor: "created_at_time",
        Cell: (tableProps) => (
          <small className="text-muted">
            {tableProps.row.original.created_at_time}
          </small>
        )
      },
      {
        Header: "Jira Ticket",
        accessor: "jira_ticket",
        Cell: (tableProps) => (
          <div className="text-nowrap">
            <a href={`https://secondsightsolutions.atlassian.net/browse/${tableProps.row.original.jira_ticket}`} target="_blank">
              <small>{tableProps.row.original.jira_ticket}</small>
            </a>
          </div>
        )
      },
      {
        Header: "Changes",
        Cell: (tableProps) => (
          <Button onClick={() => viewChanges(tableProps.row.original)} className="btn-sm">
            <i className="fa fa-eye"></i>
          </Button>
        )
      }
    ],
    []
  );

  useEffect(() => {
    if (!versions) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.activities_path);
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = await response.json();
          setVersions(data);
        } catch (error) {
          console.error("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    } else {
      setIsLoading(false);
    }
  }, []);

  function viewChanges(row) {
    setChangesTitle(row.item_type + " " + row.item_id)
    setObjectChanges(row.object_changes);
    setShowActivityModal(true)
  }

  function ChangesModal(object_changes) {
    return (
      <div>
        {object_changes.constructor == Array && object_changes.length != 0 ?
          object_changes.map((obj) => (
            <div className="mb-2 card card-sm">
              <div className="card-header">
                <small className="text-muted">
                  {obj.change_type}:
                </small> {obj.changed}
              </div>
              <div className="card-body text-muted">
                <small>From</small>
                <div className="badge bg-light text-danger">{obj.previous_value || "NULL"}</div>
                <small>to</small>
                <div className="badge bg-light text-success">{obj.new_value || "NULL"}</div>
              </div>
            </div>
          ))
        : <i className="text-muted"><br/>Details of this change are unavailable.</i>}
      </div>
    )
  }

  return (
    <>
      {isLoading ?
        <Loading />
      :
        <>
          <CustomTable data={versions} columns={activityColumns} />
          <Modal
            show={showActivityModal}
            title={changesTitle}
            modalId="changes_modal"
            body={ChangesModal(objectChanges)}
            buttonText="Ok"
            buttonType="primary"
            dismiss={() => {
              setShowActivityModal(false);
            }}
          />
        </>
      }
    </>
  );
};

export default Activities;
