import React from "react";
import { Container, Row, Col, Form, Button, Card, Image } from "react-bootstrap";
import empty from "../../../assets/images/empty.png";

function Verify({ user }) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content");
  const phoneLast4 = user.phone.slice(-4);
  const email2faLink = `/users/${user.id}/email_2fa`;

  return (
    <Container className="login-container">
      <Row className="justify-content-center align-items-center bg-light">
        <Col md={6} className="p-4">
          <Card className="verify-check-ss">
            <Image className="company-logo" src={empty} alt="company-logo" style={{ width: "350px", height: "auto" }} />
          </Card>
        </Col>
        <Col md={6} className="p-4">
          <h2 className="header-login mb-4">2FA Verification</h2>
          <div className="mb-3">
            <p>{`We sent a code to your phone - (***)***${phoneLast4}`}</p>
            <Form action="/verify" method="post">
              <input type="hidden" name="authenticity_token" value={csrfToken} />
              <input type="hidden" name="user_id" value={user.id} />
              <Form.Group className="mb-3">
                <Form.Label htmlFor="code">Code</Form.Label>
                <Form.Control placeholder="******" type="text" id="code" name="code" />
              </Form.Group>
              <div className="d-flex justify-content-between">
                <a className="btn btn-outline-primary mt-2" href={email2faLink}>
                  Email 2FA Code
                </a>
                <Button className="btn btn-primary" type="submit">
                  Continue
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Verify;
