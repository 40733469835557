import React from "react";
import { useState, useMemo, useEffect } from "react";
import CustomTable from "../CustomTable";
import { Button } from "react-bootstrap";
import titleCase from "../utils/SnakeToTitleCase";
import statusBadgeClass from "./WhollyOwnedApplication/StatusBadgeClass";
import RecordCount from "../utils/RecordCount";
import Loading from "../utils/Loading";

const WhollyOwnedApplications = (props) => {
  const [data, setData] = useState(props.applications || null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!data && props.applications_path) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.applications_path, { headers: { Accept: "application/json" } });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const fetchedData = await response.json();
          setData(fetchedData);
        } catch (error) {
          console.log("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      if (!data || data.length === 0) {
        fetchData();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);  


  function statusBadge(status) {
    status = titleCase(status);
    return <span className={`badge fw-normal text-uppercase bg-` + statusBadgeClass(status)}>{titleCase(status)}</span>;
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Entity',
        accessor: (row) => `${row.id_340b} ${row.entity}`,
        filter: "fuzzyText",
        Cell: (tableProps) => (  
          <>
          <div><b>{tableProps.row.original.entity}</b></div>
          <small>
            <a href={`/entities/340bid/${tableProps.row.original.id_340b}?tab=wo_applications`}>
              {tableProps.row.original.id_340b}
            </a>
          </small>
          </>
        )
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer",
        filter: "fuzzyText",
      },
      {
        Header: "Status",
        accessor: "status",
        filter: "fuzzyText",
        Cell: (tableProps) => statusBadge(tableProps.row.original.status),
      },
      {
        Header: "Date",
        accessor: "created_at",
        filter: "fuzzyText",
      },
      {
        Header: "Actions",
        id: "id",
        accessor: (str) => "view",
        Filter: () => {},
        Cell: (tableProps) => (
          <a href={`/wholly_owned_application/${tableProps.row.original.id}`}>
            <Button className="btn btn-primary btn-sm">Review</Button>
          </a>
        ),
      },
    ],
    []
  );

  return (
    <React.Fragment>
        {isLoading ?
          <Loading />
        : data ? <>
          {!props.hide_count ?
            <RecordCount record_count={data.length} record_type="wholly owned application" />
          : null}
          { data.length > 0 || props.hide_count ? <CustomTable columns={props.columns || columns} data={data} /> : null }
        </>: null }
    </React.Fragment>
  );
};

export default WhollyOwnedApplications;
