import React from "react";
import { useState, useMemo, useEffect, useRef } from "react";
import { ShimmerTable } from "react-shimmer-effects";
import CustomTable from "../CustomTable";
import FormModal from "../FormModal";
import Modal from "../Modal";
import { Form, Tabs, Tab } from "react-bootstrap";
import titleCase from "../utils/SnakeToTitleCase";

const InHouseCertifications = (props) => {
  const [data, setData] = useState(useMemo(() => props.certifications, []));
  const [underReview, setUnderReview] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [showModalSubmitting, setShowModalSubmitting] = useState(false);
  const [showConfirmSubmission, setShowConfirmSubmission] = useState(false);

  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.get_certifications_path, { headers: { Accept: "application/json" } });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const fetchedData = await response.json();
          setData(fetchedData);
        } catch (error) {
          console.log("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      if (!data || data.length === 0) {
        fetchData();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);


  const [modalId, setModalId] = useState(null);
  const [showModalComplete, setShowModalComplete] = useState(false);
  const [showModalDenied, setShowModalDenied] = useState(false);
  const [showModalArchive, setShowModalArchive] = useState(false);
  const [showModalNote, setShowModalNote] = useState(false);

  const [formState, setFormState] = useState({});

  const handleChange = (rowId, value) => {
    setFormState(prevState => {
      const newState = { ...prevState };
      if (value === "") {
        delete newState[rowId];
      } else {
        newState[rowId] = value;
      }
      if (newState[rowId] == "deny") {
        setModalId(rowId);
        setShowModalDenied(true);
      } else if (newState[rowId] == "archive") {
        setModalId(rowId);
        setShowModalArchive(true);
      } else if (newState[rowId] == "note") {
        setModalId(rowId);
        setShowModalNote(true);
      } else if (value === "") {
        resetAttestation(rowId);
      } else {
        completeAttestation(rowId);
      }
      return newState;
    });
  };

  const resetAttestation = (rowId) => {
    const element = document.getElementById(`completed_attestation_${rowId}`);
    element.classList.add('d-none');
    const hasIntegerKeys = Object.keys(formState).some(key => Number.isInteger(Number(key)));
    if (!hasIntegerKeys) {
      setReadyToSubmit(false);
    }
  }

  const completeAttestation = (rowId) => {
    // Get the element
    const check_element = document.getElementById(`completed_attestation_${rowId}`);
    const denial_reason_element = document.getElementById(`attestation_denial_reason_${rowId}`);

    if (formState[rowId] == "deny") {
      const denial_reason = document.getElementById(`attestation_denial_reason`).value;
      
      setFormState(prevState => {
        const newState = { ...prevState, [`denial_reason_${rowId}`]: denial_reason };
        return newState;
      });
      if ( Boolean(denial_reason) ) {
        denial_reason_element.innerHTML = denial_reason;
        denial_reason_element.classList.remove('d-none');
        if (check_element && check_element.classList.contains('d-none')) {
          check_element.classList.remove('d-none');
        }
      } else {
        denial_reason_element.innerHTML = "You must enter a denial reason.";
        denial_reason_element.classList.remove('d-none');
        if (check_element && !check_element.classList.contains('d-none')) {
          check_element.classList.add('d-none');
        }
      }
    } else if (formState[rowId] == "archive") {
      const archive_jira_element = document.getElementById(`attestation_archive_jira_${rowId}`);
      const archive_jira = document.getElementById("attestation_archive_jira").value;
      
      setFormState(prevState => {
        const newState = { ...prevState, [`archive_jira_${rowId}`]: archive_jira };
        return newState;
      });
      archive_jira_element.classList.remove('d-none');

      archive_jira_element.innerHTML = `Archived via ${archive_jira || "ENTER JIRA"}`;
      const archive_button = document.getElementById(`archive_button_${rowId}`);
      archive_button.classList.add('d-none');
      if (check_element && check_element.classList.contains('d-none')) {
        check_element.classList.remove('d-none');
      }
    } else if (formState[rowId] == "note") {
      const note_element = document.getElementById(`attestation_note_${rowId}`);
      const note = document.getElementById("attestation_note").value;

      setFormState(prevState => {
        const newState = { ...prevState, [`note_${rowId}`]: note };
        return newState;
      });
      note_element.classList.remove('d-none');

      note_element.innerHTML = note;
      if (check_element && check_element.classList.contains('d-none')) {
        check_element.classList.remove('d-none');
      }

    } else {
      setFormState(prevState => {
        const newState = { ...prevState, [`denial_reason_${rowId}`]: null };
        return newState;
      });
      denial_reason_element.innerHTML = "";
      denial_reason_element.classList.add('d-none');
      if (check_element && check_element.classList.contains('d-none')) {
        check_element.classList.remove('d-none');
      }  
    }
    setReadyToSubmit(true);
  }

  const handleSubmit = async (event) => {
    setShowModalSubmitting(true);
    setIsLoading(true);    
    const endpoint = '/in_house_attestations/bulk_update';
  
    try {
      const requestBody = {
        attestations: formState
      };
  
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        body: JSON.stringify(requestBody),
      });
  
      // Check if the request was successful
      if (response.ok) {
        console.log('Data sent successfully');
      } else {
        console.log('Error: ', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    location.reload();
  };
  
  const columns = useMemo(
    () => [  
      {
        Header: "NPI",
        accessor: "npi",
        filter: "fuzzyText",
        Cell: (row) => (
          <h5>
            <div className="badge bg-white text-muted">
              {row.value}
            </div>
          </h5>
        )
      },
      {
        Header: "In-House Determination",
        accessor: "",
        filter: "fuzzyText",
        Cell: (row) => (
          row.row.original.status == "complete" ? 
          <>
            <b className="text-success">NOT IN-HOUSE</b>
          </>            
          : row.row.original.status == "in_review" ?
          <>
            <i className="text-warning" title="Not yet approved.">NO</i>
            <span className="badge bg-white text-muted ms-2">Pending review/approval</span>
          </>
          : row.row.original.status == "denied" ?
           <><b className="text-danger">IN-HOUSE</b> <small className="text-muted">(review denied)</small></>
          : <small className="text-muted">--</small>
        )
      },
      {
        Header: "Status",
        accessor: "status",
        filter: "fuzzyText",
        Cell: (row) => (
          <div className={`badge text-uppercase ${row.value == "complete" ? "bg-success" : row.value == "denied" ? "bg-danger" : "bg-white text-muted border"}`}>
            {row.value == "complete" ? "Approved" : titleCase(row.value) || "Not Started" }
          </div>
        )
      },
      {
        Header: "Pharmacy License",
        accessor: "pharmacy_license_json",
        Filter: () => {},
        Cell: (row) => (
          <div className="dropdown ms-1">
          <div className="btn-group btn-group-sm">
            { row.value ?
              <a className="btn btn-primary btn-sm" href={row.row.original.pharmacy_license_json.path}>
                <i className="fa fa-download me-2"></i>
                ({row.row.original.pharmacy_license_json.extension})
              </a>
            :
            <small className="text-muted">
              --
            </small>
            }
          </div>
        </div>  
        ),
      },
      {
        Header: "Additional Documents",
        accessor: "additional_documents_json",
        Filter: () => {},
        Cell: (row) => (
          row.value ? 
            <div className="dropdown ms-1">
              <div className="btn-group btn-group-sm">
                <button
                    type="button"
                    className="btn btn-secondary btn-sm">
                    <i className="fa fa-file"></i> {row.value.length}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                  id="downloadsDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-reference="parent">
                  <span className="visually-hidden">Toggle Dropdown</span>
                </button>
                <ul className="dropdown-menu" aria-labelledby="downloadsDropdown">
                  { row.value.map((document) => (
                    <li>
                      <a className="dropdown-item" href={document.path}>
                      <i className="fa fa-download me-2"></i>
                        <small>{document.filename}</small>
                      </a>
                    </li>
                  ))}
                </ul>            
              </div>
           </div>  
          :
            <small className="text-muted">
              --
            </small>          
        ),
      },

      {
        Header: "Select approval status:",
        Cell: (row) => (
          <>
            { row.row.original.status == "in_review" && !row.row.original.deleted_at ?
              <>
              <div className="input-group" role="group" id={`attestation_status_${row.row.original.id}`}>
                <select
                  name={`approval[${row.row.original.id}]`}
                  value={formState[row.row.original.id]}
                  className="form-select form-select-sm"
                  onChange={(event) => handleChange(row.row.original.id, event.target.value)}
                >
                  <option value="" selected={formState[row.row.original.id] == "" ? "selected" : null}>{"< Make A Selection >"}</option>
                  <option value="approve" selected={formState[row.row.original.id] == "approve" || row.row.original.status == "complete" ? "selected" : null}>Approve</option>
                  <option value="deny" selected={formState[row.row.original.id] == "deny" || row.row.original.status == "denied" ? "selected" : null}>Deny</option>
                  <option value="note">+ Add Note</option>
                </select>
                <div className={`input-group-text ${row.row.original.status == "in_review" ? 'd-none' : null} bg-success`} id={`completed_attestation_${row.row.original.id}`}>
                  <i className="fa fa-check text-white"></i>
                </div>
              </div>
            </>
            : row.row.original.status == "in_progress" && !row.row.original.deleted_at ?
              <label
                className="btn btn-sm bg-white text-muted border"
                onClick={() => handleChange(row.row.original.id, "note")}
              >
                + Add Note
              </label>
            : <small className="text-muted">--</small>
            }
            <div 
              id={`attestation_denial_reason_${row.row.original.id}`} 
              className={`smaller alert alert-danger alert-sm mb-0 mt-2 fs-9 p-2${row.row.original.status != "denied" ? ' d-none' : null}`} 
              role="button"
              onClick={() => { handleChange(row.row.original.id, "deny") }}
            >
              {row.row.original.denial_reason}
            </div>
            <div 
              id={`attestation_note_${row.row.original.id}`} 
              className={`smaller alert alert-info alert-sm mb-0 mt-2 fs-9 p-2${!row.row.original.note ? ' d-none' : null}`} 
              role="button"
              onClick={() => { handleChange(row.row.original.id, "note") }}
            >
              {row.row.original.note}
            </div>                
            <span 
              id={`attestation_archive_jira_${row.row.original.id}`} 
              className="badge bg-warning d-none"
              role="button"
              onClick={() => { handleChange(row.row.original.id, "archive") }}>
            </span>
          </>
        ),
      },
      {
        Header: "Archive",
        Cell: (row) => (
          <center>
            { row.row.original.deleted_at ?
              <small>
                <i className="text-danger">Archived</i>
              </small>                
            : row.row.original.status &&
	      row.row.original.status != "not_started" &&
	      row.row.original.status != "in_progress" ?
              <div 
              className="btn btn-sm btn-danger"
              id={`archive_button_${row.row.original.id}`}
              onClick={(event) => handleChange(row.row.original.id, "archive")}>
                <i className="fa fa-remove"></i>
              </div>
            : <small className="text-muted">--</small> }
          </center>
        )        
      }
    ],
    []
  );

  const ConfirmationPrompt = () => {
    return (
      <>
        <p>Are you sure you wish to submit these determinations?</p>
        {
          data.in_review.length == 0 ?
            <b className="text-danger">NOTE: Users associated with the covered entity will be notified of this.</b>
          : 
            <p><b className="text-danger">NOTE:</b> There will be no notifications to covered entity users until all outstanding NPIs have a determination.</p>
        }
        <ul className="list-group">
          {Object.entries(formState).map(([key, value]) => {
            const record = ([...data.in_review, ...data.complete, ...data.denied]).find(item => item.id == key);
            return record ? (
		<li className="list-group-item" key={key}>{record.npi} {">"} {
		    value == "approve" ?
		    <>Not In-House</>
      	          : value == "archive" ?
		    <>Archive</>
  	 	  : value == "deny" ?
		    <>In-House</>
		  : value == "note" ?
     	            <>Add note</>
		  : "--"
		}</li>
            ) : (
              null
            );
          })}
        </ul>
      </>
    );
  };
  
  const AttestationPrompt = (prompt_props) => {
    const innerRef = useRef();
  
    useEffect(() => {
        if (innerRef.current) {
          innerRef.current.focus();
        }
      }, [prompt_props.role]);
  
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-sm-12">
            <small>
              Regarding NPI:<br />
              <b>{titleCase(prompt_props.attestation.npi)}</b>
            </small>
          </div>
        </div>
        <div className="mt-4">
          {prompt_props.role === "deny" ? (
            <Form.Group className="mb-3" controlId="denial_reason">
              <p>
                Please confirm your reason to <b className="text-danger">Deny</b> this attestation.
              </p>
              <div className="bg-body-tertiary rounded-4 p-4 border">
                <Form.Label>
                  <b className="text-danger">Denial Reason:</b> <small className="text-muted">(required)</small>
                </Form.Label>
                <Form.Control
                  _ref={innerRef}
                  required
                  as="textarea"
                  name="attestation[denial_reason]"
                  placeholder="Enter your denial reason..."
                  id="attestation_denial_reason"
                />
              </div>
            </Form.Group>
          ) : prompt_props.role === "archive" ? (
            <Form.Group className="mb-3" controlId="archive_jira">
              <p>
                Please enter the JIRA ticket number associated with the request to archive this attestation.
              </p>
              <div className="bg-body-tertiary rounded-4 p-4 border">
                <Form.Label>
                  <b className="text-danger">JIRA Ticket Number:</b> <small className="text-muted">(required)</small>
                </Form.Label>
                <Form.Control
                  _ref={innerRef}
                  required
                  placeholder="Enter JIRA ticket..."
                  id="attestation_archive_jira"
                />
              </div>
            </Form.Group>
          ) : prompt_props.role === "note" ? (
            <Form.Group className="mb-3" controlId="attestation_note">
              { prompt_props.attestation.notes.length > 0 ?
                <ul className="list-group mb-3">
                  {prompt_props.attestation.notes.map((note) => {
                    return (<li className="list-group-item">
                      <small className="text-muted">{note.content}</small>
                    </li>)
                  })}
                </ul>
              : null }
              <div className="bg-body-tertiary rounded-4 p-4 border">
                <Form.Label>Note:</Form.Label>
                <Form.Control
                  _ref={innerRef}
                  required
                  as="textarea"
                  placeholder="Enter Note..."
                  id="attestation_note"
                />
              </div>
            </Form.Group>
          ) : (
            <p>
              Please confirm that you wish to <b className="text-success">ACCEPT</b> this attestation.
            </p>
          )}
        </div>
      </React.Fragment>
    );
  };
  

  const defaultTab = () => {
    if (data.in_review.length > 0) {
      return "in_review";
    } else if (data.complete.length > 0) {
      return "complete";
    } else if (data.denied.length > 0) {
      return "denied";
    } else if (data.archived.length > 0) {
      return "archived";
    } else if (data.archived.length > 0) {
      return "not_started";
    }
  }

  return (
    <React.Fragment>
      {isLoading ?
        <ShimmerTable row={5} col={5} />
      : <>
        <div className="row mb-3">
          <div className="col-3">
            <h6>Policy:</h6>
            <a className="mb-3 text-muted" onClick={() => setUnderReview(true)}>
              <b>Has In-House Pharmacy?</b>
            </a>
          </div>
          <div className="col-3">
            <h6>Due Date:</h6>
            {props.due_date}
          </div>
          <div className="col-3">
            <h6>Download All Documents:</h6>
            <a href={props.download_pdfs_link} className="btn bg-white border btn-sm">
              <i className="fa fa-download me-2"></i>
              Download (.zip)
            </a>
          </div>          
          <div className="col-3">
            <h6>CSV Export:</h6>
            <a href={props.export_csv_link} className="btn bg-white border btn-sm">
              <i className="fa fa-download me-2"></i>
              Download (.csv)
            </a>
          </div>
        </div>
          { !underReview ?
            <div className="btn btn-primary btn-lg d-block" onClick={() => setUnderReview(true)}>
              Begin Review
            </div>
          :
            <>
            <Tabs defaultActiveKey={props.tab || defaultTab} id="attestation_tabs" className="">
              {(data.in_review.length > 0) ? (
                <Tab
                  eventKey="in_review"
                  className="bg-white p-4 pb-1 border border-top-0 rounded-bottom"
                  title={
                    <>
                      <i className="fa fa-search"></i> In Review <span className="badge bg-warning">{data.in_review.length}</span>
                    </>
                  }>
                  <p className="text-muted">
                    Select approval status for each applicable line item. <b className="text-danger">When finished, click "Submit" at the bottom to save all of your selections.</b>
                  </p>
                  <CustomTable columns={columns} data={data.in_review} page_size={100} hide_page_size_if_unneeded={true} /> 
                  { readyToSubmit ? 
                    <a type="submit" className="btn btn-success btn-lg d-block" onClick={() => setShowConfirmSubmission(true)}>
                      Submit
                    </a> 
                  : null }
                </Tab>
                )
              : null}
            {(data.in_progress.length > 0) ? (
                <Tab
                  eventKey="in_progress"
                  className="bg-white p-4 pb-1 border border-top-0 rounded-bottom"
                  title={
                    <>
                      <i className="fa fa-clock"></i> In Progress <span className="badge bg-primary border">{data.in_progress.length}</span>
                    </>
                  }>
                  <CustomTable columns={columns} data={data.in_progress} page_size={100} hide_page_size_if_unneeded={true} /> 
                  { readyToSubmit ?
                    <a type="submit" className="btn btn-success btn-lg d-block" onClick={() => setShowConfirmSubmission(true)}>
                      Submit
                    </a>
                  : null }
                </Tab>
                )
              : null}                         
              {(data.complete.length > 0) ? (
                <Tab
                  eventKey="complete"
                  className="bg-white p-4 pb-1 border border-top-0 rounded-bottom"
                  title={
                    <>
                      <i className="fa fa-check"></i> Approved <span className="badge bg-success">{data.complete.length}</span>
                    </>
                  }>
                  <CustomTable columns={columns} data={data.complete} page_size={100} hide_page_size_if_unneeded={true} /> 
                  { readyToSubmit ? 
                    <a type="submit" className="btn btn-success btn-lg d-block" onClick={() => setShowConfirmSubmission(true)}>
                      Submit
                    </a> 
                  : null }
                </Tab>
              )
              : null}
              {(data.denied.length > 0) ? (
                <Tab
                  eventKey="denied"
                  className="bg-white p-4 pb-1 border border-top-0 rounded-bottom"
                  title={
                    <>
                      Denied <span className="badge bg-danger">{data.denied.length}</span>
                    </>
                  }>
                    <CustomTable columns={columns} data={data.denied} page_size={100} hide_page_size_if_unneeded={true} /> 
                    { readyToSubmit ?
                    <a type="submit" className="btn btn-success btn-lg d-block" onClick={() => setShowConfirmSubmission(true)}>
                      Submit
                    </a>
                  : null }
                </Tab>
              )
              : null}
              {(data.archived.length > 0) ? (
                <Tab
                  eventKey="archived"
                  className="bg-white p-4 pb-1 border border-top-0 rounded-bottom"
                  title={
                    <>
                      Archived <span className="badge bg-secondary">{data.archived.length}</span>
                    </>
                  }>
                  <CustomTable columns={columns} data={data.archived} page_size={100} hide_page_size_if_unneeded={true} />
                </Tab>              
              )
              : null}
              {(data.not_started.length > 0) ? (
                <Tab
                  eventKey="not_started"
                  className="bg-white p-4 pb-1 border border-top-0 rounded-bottom"
                  title={
                    <>
                      Not Started <span className="badge bg-white text-muted border">{data.not_started.length}</span>
                    </>
                  }>
                  <CustomTable columns={columns} data={data.not_started} page_size={100} hide_page_size_if_unneeded={true} /> 
                </Tab>
              )
              : null}
            </Tabs>
            </>
          }
          <FormModal
              show={showModalComplete}
              title="Complete Attestation"
              buttonText="Complete Attestation"
              buttonType="success"
              modalId={`complete_attestation_modal_${modalId}`}
              fields={<AttestationPrompt attestation={data.in_review.find((row) => row.id == modalId)} role="complete" />}
              method="POST"
              action={`/attestations/${data.in_review.find((row) => row.id == modalId)?.id}/complete`}
              dismiss={() => {
                setShowModalComplete(false);
              }}
            />
            <Modal
              show={showModalDenied}
              title="Deny Attestation"
              buttonText="Deny Attestation"
              buttonType="danger"
              modalId={`deny_attestation_modal${modalId}`}
              body={<AttestationPrompt attestation={data.in_review.find((row) => row.id == modalId)} role="deny" />}
              action={() => {completeAttestation(modalId);setShowModalDenied(false);}}
              dismiss={() => {
                setShowModalDenied(false);
              }}
            />
            <Modal
              show={showModalArchive}
              title="Archive Attestation"
              buttonText="Archive On Submit"
              buttonType="danger"
              modalId={`archive_attestation_modal${modalId}`}
              body={<AttestationPrompt attestation={[...data.in_review, ...data.complete, ...data.denied].find((row) => row.id == modalId)} role="archive" />}
              action={() => {
                completeAttestation(modalId);
                setShowModalArchive(false);
              }}
              dismiss={() => {
                setShowModalArchive(false);
              }}
            />
            <Modal
              show={showModalNote}
              title="Add Note"
              buttonText="Add Note"
              buttonType="primary"
              modalId={`note_attestation_modal${modalId}`}
              body={<AttestationPrompt attestation={[...data.in_review, ...data.complete, ...data.denied, ...data.in_progress].find((row) => row.id == modalId)} role="note" />}
              action={() => {
                completeAttestation(modalId);
                setShowModalNote(false);
              }}
              dismiss={() => {
                setShowModalNote(false);
              }}
            />
            <Modal
              show={showConfirmSubmission}
              title="Submit Attestation Updates"
              buttonText="Yes, please submit"
              buttonType="danger"
              modalId={`confirm_submission_modal${modalId}`}
              body={<ConfirmationPrompt />}
              action={() => {handleSubmit()}}
              dismiss={() => {
                setShowConfirmSubmission(false);
              }}
            />            
        </>
      }     
      <Modal
        show={showModalSubmitting}
        title="Submitting Attestation Updates"
        modalId={`submitting_attestation_modal${modalId}`}
        body="Your updates are being submitted..."
        disableFooter={true}        
      />
    </React.Fragment>
  );
};

export default InHouseCertifications;
