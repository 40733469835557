import React from "react";
import { useState } from "react";
import FormModal from "../FormModal";

const InHouseStatus = (props) => {
  const [showCEDesignationsModal, setShowCEDesignationsModal] = useState(false);
  const [showIHDesignationsModal, setShowIHDesignationsModal] = useState(false);

  return (
    <React.Fragment>
      { props.entity.designation_permitted_status == 'has_inhouse_pharmacy' ?
        <>
          <div className="jumbotron p-4 mb-3 bg-body-tertiary rounded-4">
            <small className="text-muted">This entity has <b>in-house pharmacy permitted designations</b> status.</small>
            <button
              style={{ marginRight: "10px" }}
              onClick={() => {
                setShowCEDesignationsModal(true);
              }}
              className="btn btn-primary ms-2 btn-sm">
              Clear Status
            </button>
          </div>
          <FormModal
            show={showCEDesignationsModal}
            dynamicWidth={true}
            dynamicHeight={true}
            title={`${props.entity.id_340b} - Clear Permitted Designations Status`}
            modalId={`ce_designation_modal_${props.entity.id}`}
            buttonText="Clear"
            buttonType="primary"
            method="POST"
            fields={`Are you sure you want to clear ${props.entity.id_340b} permitted designations status?`}
            action={`/entities/in_house_pharmacies/${props.entity.id}/clear_in_house_status`}
            dismiss={() => {
              setShowCEDesignationsModal(false);
            }}
          />
        </>
      : props.in_house_designations_exist ?
      <>
      <div className="jumbotron p-4 mb-3 bg-body-tertiary rounded-4">
        <small className="text-muted">This entity has <b>in-house designations</b>.</small>
        <button
          style={{ marginRight: "10px" }}
          onClick={() => {
            setShowIHDesignationsModal(true);
          }}
          className="btn btn-primary ms-2 btn-sm">
          Clear In-House Designation Flags
        </button>
      </div>
      <FormModal
        show={showIHDesignationsModal}
        dynamicWidth={true}
        dynamicHeight={true}
        title={`${props.entity.id_340b} - Clear In-House Designation Flags`}
        modalId={`ih_designation_modal_${props.entity.id}`}
        buttonText="Clear"
        buttonType="primary"
        method="POST"
        fields={`Are you sure you want to clear ${props.entity.id_340b} in-house designation flags?`}
        action={`/entities/${props.entity.id}/clear_in_house_designation_flags`}
        dismiss={() => {
          setShowIHDesignationsModal(false);
        }}
      />
    </>
      : null }
    </React.Fragment>
  );
};

export default InHouseStatus;
