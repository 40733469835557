import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Card, Image } from "react-bootstrap";
import logo from "../../../assets/images/logo.png";
import teamImg from "../../../assets/images/support_teams.png";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Container fluid className="login-container">
      <Row className="justify-content-center align-items-center bg-light">
        <Col md={6} className="p-4">
          <Card className="logo-card">
            <Image
              className="mt-2 ml-3"
              src={logo}
              alt="company-logo"
              style={{ width: "150px", height: "auto" }} // Adjust the width as needed
            />
            <h1 className="font-weight-bold mt-2 mb-2">Support Mgmt Platform</h1>
            <p>Everything you need for convenient team work</p>
          </Card>
          <Card className="mt-4">
            <Card.Img variant="top" src={teamImg} alt="teamwork-img" />
          </Card>
        </Col>
        <Col md={6} className="p-4">
          <Card className="shadow-lg">
            <Card.Body>
              <Form className="mt-4" method="POST" action="/login">
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={document.querySelector('meta[name="csrf-token"]').getAttribute("content")}
                />
                <Form.Group controlId="formEmail">
                  <Form.Control
                    type="email"
                    id="email"
                    name="session[email]"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mt-2" controlId="formPassword">
                  <Form.Control
                    type="password"
                    id="password"
                    name="session[password]"
                    placeholder="**********"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Form.Group>
                <div className="d-grid mt-3">
                  <Button variant="primary" type="submit">
                    Log in
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
