import React from "react";
import { useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import FormModal from "../FormModal";
import { Button, Form } from "react-bootstrap";
import RecordCount from "../utils/RecordCount";

const BmsDesignations = (props) => {
  const [data, setData] = useState(useMemo(() => props.bms_des, []));
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [modalEditId, setModalEditId] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "340B ID",
        accessor: "id_340b",
        Cell: (row) => <a href={`/entities/340bid/${row.value}`}>{row.value}</a>
      },
      {
        Header: "Pharmacy ID",
        accessor: "pid",
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer_name",
        filter: "fuzzyText",
        Cell: (row) => <small className="text-muted">{row.value}</small>
      },
      {
        Header: "HIN",
        accessor: "health_industry_number",
      },
      {
        Header: "Flag",
        accessor: "flag",
        Filter: () => {},
        Cell: (row) => <small className="badge bg-light text-dark fw-normal">{row.value}</small>
      },
      {
        Header: "Assigned",
        accessor: "assigned",
        Filter: () => {},
        Cell: (tableProps) => <div>{tableProps.row.original.assigned ? "True" : "False"}</div>,
      },
      {
        Header: "Kind",
        accessor: "kind",
        Filter: () => {},
      },
      {
        Header: "Completed",
        accessor: "completed_at",
        Filter: () => {},
      },
      {
        Header: "Last Updated",
        accessor: "updated_at",
        Filter: () => {},
      },
      {
        Header: "Actions",
        id: "id",
        accessor: (str) => "delete",
        Filter: () => {},
        Cell: (tableProps) => (
          <div>
            { props.can_edit ?
            <Button
              className="btn btn-primary"
              onClick={() => {
                setModalEditId(tableProps.row.original.id);
                setShowModalEdit(true);
              }}>
              Edit
            </Button>
            : "N/A" }
          </div>
        ),
      },
    ],
    []
  );

  const EditBMSDesignation = (props) => {
    return (
      <React.Fragment>
        <Form.Group className="mb-3" controlId="id_340b">
          <h6>340B ID:</h6>
          {props.data.id_340b || <small className="text-muted"><i>None</i></small>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="manufacturer_name">
          <h6>Manufacturer:</h6>
          {props.data.manufacturer_name || <small className="text-muted"><i>None</i></small>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="pid">
          <h6>HRSA Pharmacy ID:</h6>
          {props.data.pid || <small className="text-muted"><i>None</i></small>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="flag">
          <h6>Flag:</h6>
          {props.data.flag || <small className="text-muted"><i>None</i></small>}
        </Form.Group>
        <Form.Group className="mb-3" controlId="external_contact_email">
          <Form.Label>Primary Email Contact</Form.Label>
          <Form.Control
            required
            type="email"
            name="bms_des[external_contact_email]"
            defaultValue={props.data.external_contact_email}
            placeholder="Enter external contact email..."
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="secondary_contact_email">
          <Form.Label>Secondary Email Contact</Form.Label>
          <Form.Control
            type="email"
            name="bms_des[secondary_contact_email]"
            defaultValue={props.data.secondary_contact_email}
            placeholder="Enter secondary contact email..."
          />
        </Form.Group>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="pt-3 pb-2">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="h2">
            <i className="fa fa-envelope pe-2"></i>
            BMS Email Update
          </h1>
        </div>
      </div>
      <p className="text-muted">Update primary and secondary contacts of Bristol Myers Squibb designations for recertification.</p>
      <RecordCount record_count={data.length} record_type="designation" />
      <CustomTable columns={columns} data={data} />
      <FormModal
        show={showModalEdit}
        title={`Edit Designation ${data.find((row) => row.id == modalEditId)?.id_340b}, PID: ${
          data.find((row) => row.id == modalEditId)?.pid
        }`}
        buttonText="Submit"
        buttonType="primary"
        modalId={`edit_designation_modal_${modalEditId}`}
        method="POST"
        fields={<EditBMSDesignation data={data.find((row) => row.id == modalEditId)} />}
        action={`/designations/${modalEditId}/update_bms_email`}
        dismiss={() => {
          setShowModalEdit(false);
        }}
      />
    </React.Fragment>
  );
};

export default BmsDesignations;
