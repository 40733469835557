import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";

export default function EligibilityStatusSummary(props) {
    const countEligible = (row) => {
        let count = 0;
    
        _.each(props.eligibility, (val, key) => {
          _.each(val[row.original.pharmacy_id].networks, (network) => {
            if (
              val[row.original.pharmacy_id][network] == "ELIGIBLE" ||
              val[row.original.pharmacy_id][network] == "IN_PROCESS"
            ) {
              count += 1;
            }
          });
        });
    
        return count;
      };
    
      const countIneligible = (row) => {
        let count = 0;
    
        _.each(props.eligibility, (val, key) => {
          _.each(val[row.original.pharmacy_id].networks, (network) => {
            if (
              val[row.original.pharmacy_id][network] == "INELIGIBLE" ||
              val[row.original.pharmacy_id][network] == "INELIGIBLE_MISSING_CLAIMS"
            ) {
              count += 1;
            }
          });
        });
    
        return count;
      };
    
      const countAtRisk = (row) => {
        let count = 0;
    
        _.each(props.eligibility, (val, key) => {
          _.each(val[row.original.pharmacy_id].networks, (network) => {
            if (val[row.original.pharmacy_id][network] == "AT_RISK") {
              count += 1;
            }
          });
        });
    
        return count;
      };
    

  return (
    <div style={{cursor: "pointer"}}>
    <div className="badge bg-warning text-dark me-1">{countAtRisk(props.row)}</div>
    <div className="badge bg-success me-1">{countEligible(props.row)}</div>
    <div className="badge bg-danger me-1">{countIneligible(props.row)}</div>        
    </div>
  );
}
