import React from "react";
import { useState, useMemo, useEffect } from "react";
import Loading from "../utils/Loading";
import CustomTable from "../CustomTable";

const InHouseAttestations = (props) => {
  const [data, setData] = useState(useMemo(() => props.attestations, []));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.get_attestations_path, { headers: { Accept: "application/json" } });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const fetchedData = await response.json();
          setData(fetchedData);
        } catch (error) {
          console.log("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      if (!data || data.length === 0) {
        fetchData();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  const [modalId, setModalId] = useState(null);
  const [showModalComplete, setShowModalComplete] = useState(false);
  const [showModalDenied, setShowModalDenied] = useState(false);
  const [showModalArchive, setShowModalArchive] = useState(false);

  const columns = useMemo(
    () => [  
      {
        Header: "Covered Entity",
        accessor: (row) => `${row.id_340b} ${row.entity_name}`,
        filter: "fuzzyText",
        Cell: (row) => <>
          <a href={row.row.original.review_link}>
            <b>{row.row.original.entity_name}</b>
          </a>
          <br/>
          <a href={`/entities/340bid/${row.row.original.id_340b}`} target="_blank">
            <small className="text-muted">{row.row.original.id_340b}</small>
          </a>
        </>          
      },
      {
        Header: "Has In House?",
        accessor: (row) => (row.attested_in_house == false || (!row.attested_in_house && row.has_inhouse_pharmacy == false)) ? "FALSE" : "TRUE",
        filter: "fuzzyText",
        Cell: (row) =>
          <small className="text-muted">
            { row.row.original.attested_in_house == true && row.row.original.has_inhouse_pharmacy != true ?
 	       <i>TRUE <small className="text-warning">(pending)</small></i>
             : row.row.original.attested_in_house == false && row.row.original.has_inhouse_pharmacy != false ?
 	       <i>FALSE <small className="text-warning">(pending)</small></i>

             : row.row.original.has_inhouse_pharmacy == true ?
	       "TRUE"
             : row.row.original.has_inhouse_pharmacy == false ?
	       "FALSE"
             :
	      <small className="text-muted">--</small>
	     }
        </small>
      },
      {
        Header: "Status",
        accessor: "status",
        filter: "fuzzyText",
        Cell: (row) => (<small className={`badge ${row.value == "Complete" ? "bg-success" : row.value == "Denied" ? "bg-danger" : row.value == "In Review" || row.value == "Awaiting Flag" ? "bg-warning" : "bg-white text-muted border"}`}>{row.value}</small>)
      },
      { 
        Header: "Needing Certification",
        accessor: "attestations",
        Filter: () => {},
        Cell: (row) => <>
          {row.row.original.attested_in_house ? <small>--</small> :
            <>
              {row.row.original.completed_attestations_count > 0 ?
                  <div className="badge bg-success me-1" title="Completed">
                    {row.row.original.completed_attestations_count}
                  </div>
                : null }
                { row.row.original.in_review_attestations_count > 0 ?
                  <div className="badge bg-warning me-1" title="In Review">
                    {row.row.original.in_review_attestations_count}
                  </div>
                : null }
                { row.row.original.in_progress_attestations_count > 0 ?
                  <div className="badge bg-primary me-1" title="In progress">
                    {row.row.original.in_progress_attestations_count}
                  </div>
                : null }
                { row.row.original.denied_attestations_count > 0 ?
                  <div className="badge bg-danger me-1" title="Denied">
                    {row.row.original.denied_attestations_count}
                  </div>
                : null }
                { row.row.original.archived_attestations_count > 0 ?
                  <div className="badge bg-secondary border me-1" title="Archived">
                    {row.row.original.archived_attestations_count}
                  </div>
                : null }
                { row.row.original.not_started_attestations_count > 0 ?
                  <div className="badge bg-white border text-muted" title="Not Started">
                    {row.row.original.not_started_attestations_count}
                  </div>
              : null }
            </>
          }
        </>
      },
      {
        Header: "Submitted",
        accessor: "submitted_at",
        Filter: () => {},
        Cell: (row) => (<small className="text-muted">{row.value || "--"}</small>)
      },
      {
        Header: "Last Updated",
        accessor: "updated_at",
        Filter: () => {},
        Cell: (row) => (<small className="text-muted">{row.value || "--"}</small>)
      },
      {
        Header: "Due Date",
        accessor: "due_at",
        Filter: () => {},
        Cell: (row) => (<small className="text-muted">{row.value || "--"}</small>)
      },
      {
        Header: "Review",
        accessor: "review_link",
        Filter: () => {},
        Cell: (row) => (
          row.row.original.status == "Complete" ?
            <i className="fa fa-check text-success"></i>
          : row.value ?
            <a href={row.value} className="btn btn-primary btn-sm">Review</a>
          : <small className="text-muted">--</small>
        ),
      },
    ],
    []
  );

  const CsvDownload = (props) => {
    return (
      <div className="bg-body-tertiary p-2 rounded-4 mb-3 text-center">
        <small><b>{props.attestations_count}</b> record(s) found. </small>
        <a href={props.download_link} className="btn btn-sm bg-white border ms-2">
          <i className="fa fa-download me-2"></i>
          Export (.csv)
        </a>
      </div>
    )
  }

  return (
    <React.Fragment>
      {isLoading ?
        <Loading />
      : <>
        { data.length > 0 ?
          <>
            <CsvDownload attestations_count={data.length} download_link={props.export_attestations_path} />
            <CustomTable columns={columns} data={data} /> 
          </>
        : 
          <div className="bg-body-tertiary p-4 rounded-4 text-center text-muted">
            <i>No results found.</i>
          </div>
        }         
        </>
      }            
    </React.Fragment>
  );
};

export default InHouseAttestations;
