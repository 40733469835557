import React from "react";
import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import JiraTicket from "../JiraTicket";

const NewEffectiveDateForm = (props) => {
  const [pharmacyId, setPharmacyId] = useState(null);
  const [searchPid, setSearchPid] = useState("");
  const [search340bId, set340bId] = useState(props.id_340b || "");
  const [coveredEntity, setCoveredEntity] = useState(null);
  const [networkOpts, setNetworkOpts] = useState([]);

  useEffect(() => {
    if (search340bId.length > 2 || searchPid.length > 2) {
      fetch(
        `/api/form_validations/id_340b_and_pharmacy_id?pharmacy_id=${searchPid}&id_340b=${search340bId}`,
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.response_code == "201") {
            // Set covered entity if found.
            // Set pharmacy if found.
            // Auto fill HIN if pharmacy is found.
            setCoveredEntity(data.covered_entity);
            if (data.pharmacy_id) {
              setPharmacyId(data.pharmacy_id);
            } else {
              // Clear HIN and pharmacy if no pharmacy is found.
              setPharmacyId(null);
            }
          } else {
            if (!data.covered_entity) {
              // Clear all state fields if there is no matching covered entity.
              setCoveredEntity(null);
            }
            setPharmacyId(null);
          }
        });
    }
  }, [searchPid, search340bId]);

  const handleOnChange = (e) => {
    e.preventDefault();
  };

  return (
    <React.Fragment>
      <Form.Group className="mb-3" controlId="id_340b">
        <Form.Label>
          340B ID <small className="text-danger">*</small>
          {coveredEntity ? <small className="text-success ms-1">{coveredEntity}</small> : null}
        </Form.Label>
        <Form.Control
          type="text"
          name="effective_date[id_340b]"
          placeholder="Enter 340B ID..."
          value={props.id_340b}
          onBlur={(e) => {
            set340bId(e.target.value);
            handleOnChange(e);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-4" controlId="pid">
        <Form.Label>
          Pharmacy ID <small className="text-danger">*</small>
          {pharmacyId ? (
            <small>
              <i className="fa fa-check text-success ms-1"></i>
            </small>
          ) : searchPid ? (
            <small className="text-danger ms-1">
              <i className="fa fa-close"></i> does not belong to covered entity.
            </small>
          ) : null}
        </Form.Label>
        <Form.Control
          required
          type="text"
          name="effective_date[pid]"
          placeholder="Enter PID..."
          onBlur={(e) => {
            setSearchPid(e.target.value);
            handleOnChange(e);
          }}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="manufacturer">
        <Form.Label>
          Manufacturer <small className="text-danger">*</small>
        </Form.Label>
        <Form.Select
          required
          name="effective_date[manufacturer]"
          onChange={(e) => {
            const man_network = props.manufacturer_networks.find(
              (mn) => mn.manufacturer == e.target.value,
            );
            setNetworkOpts(man_network?.networks || []);
          }}
        >
          <option value="">{"< Make a Selection >"}</option>
          {props.manufacturers.map((manufacturer) => (
            <option key={manufacturer.key} value={manufacturer.key}>
              {manufacturer.name}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="kind">
        <Form.Label>
          Network <small className="text-danger">*</small>
        </Form.Label>
        <Form.Select required name="effective_date[kind]">
          <option value="">{"< Make a Selection >"}</option>
          {networkOpts.map((network) => (
            <option key={network} value={network}>
              {network}
            </option>
          ))}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3" controlId="date">
        <Form.Label>
          Effective Date <small className="text-danger">*</small>
        </Form.Label>
        <Form.Control required type="date" name="effective_date[date]" />
      </Form.Group>
      <JiraTicket />
    </React.Fragment>
  );
};

export default NewEffectiveDateForm;
