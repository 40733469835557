import React, { useEffect } from "react";
import { useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import FormModal from "../FormModal";
import Modal from "../Modal";
import { Form, Button, NavLink } from "react-bootstrap";
import activationStateBadgeClass from "./activationStateBadgeClass";
import RecordCount from "../utils/RecordCount";
import Loading from "../utils/Loading";

const Users = (props) => {
  const [data, setData] = useState(props.users || null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!data && props.users_path) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.users_path, {
            headers: { Accept: "application/json" },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const fetchedData = await response.json();
          setData(fetchedData);
        } catch (error) {
          console.log("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      if (!data || data.length === 0) {
        fetchData();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  const [showModalUpdateUser, setShowModalUpdateUser] = useState(false);
  const [modalUpdateUserId, setModalUpdateUserId] = useState(null);
  const [userToUpdate, setUserToUpdate] = useState(null);
  const [showModalUnlock, setShowModalUnlock] = useState(false);
  const [modalUnlockId, setModalUnlockId] = useState(null);
  const [entitiesTitle, setEntitiesTitle] = useState(null);
  const [userEntities, setUserEntities] = useState([]);
  const [showEntitiesModal, setShowEntitiesModal] = useState(null);
  const [showSupportModal, setShowSupportModal] = useState(null);
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [modalActivateId, setModalActivateId] = useState(null);
  const [showDisassociateModal, setShowDisassociateModal] = useState(null);
  const [supportTitle, setSupportTitle] = useState(null);
  const [showModalPW, setShowModalPW] = useState(false);
  const [modalPWId, setModalPWId] = useState(null);
  const [showModalResetPW, setShowModalResetPW] = useState(false);
  const [modalResetPWId, setModalResetPWId] = useState(null);
  const [showModalRecover, setShowModalRecover] = useState(false);
  const [modalRecoverId, setModalRecoverId] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [modalDeleteId, setModalDeleteId] = useState(null);

  const UpdateUserFormFields = (obj) => {
    return (
      <React.Fragment>
        <Form.Group className="mb-3" controlId="first_name">
          <Form.Label>First Name</Form.Label>
          <Form.Control
            required
            disabled={props.is_developer_support}
            type="text"
            name="user[first_name]"
            placeholder="Enter first name..."
            defaultValue={obj.user.first_name}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="last_name">
          <Form.Label>Last Name</Form.Label>
          <Form.Control
            required
            disabled={props.is_developer_support}
            type="text"
            name="user[last_name]"
            placeholder="Enter last name..."
            defaultValue={obj.user.last_name}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="role">
          <Form.Label>Role</Form.Label>
          {obj.user.role === "super_admin" ? (
            <Form.Control
              readOnly
              disabled={props.is_developer_support}
              type="text"
              name="user[role]"
              defaultValue={obj.user.role}
            />
          ) : (
            <Form.Select
              required
              disabled={props.is_developer_support}
              type="text"
              name="user[role]"
              defaultValue={obj.user.role}
            >
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </Form.Select>
          )}
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            required
            disabled={props.is_developer_support}
            type="text"
            name="user[email]"
            placeholder="Enter email..."
            defaultValue={obj.user.email}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="phone">
          <Form.Label>Phone</Form.Label>
          <Form.Control
            disabled={props.is_developer_support}
            type="text"
            name="user[phone]"
            placeholder="Enter phone..."
            defaultValue={obj.user.phone}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="prospect">
          <Form.Label>Prospect</Form.Label>
          <Form.Control
            readOnly
            required
            disabled={props.is_developer_support}
            type="text"
            name="user[prospect]"
            defaultValue={obj.user.prospect}
          />
        </Form.Group>
        {props.is_developer_support ? (
          <Form.Group className="mb-3" controlId="organization_id">
            <Form.Label>Organization ID</Form.Label>
            <Form.Control
              type="search"
              name="user[organization_id]"
              autoComplete="off"
              defaultValue={obj.user.organization_id}
            />
          </Form.Group>
        ) : null}
      </React.Fragment>
    );
  };

  function UnlockButton(props) {
    if (props.unlock_token) {
      return (
        <Button
          className="btn-sm btn-danger btn"
          onClick={() => {
            document.querySelectorAll(".modal-backdrop")[0].remove();
            document.querySelector("body").removeAttribute("style");
            setShowSupportModal(null);
            setModalUnlockId(props.id);
            setShowModalUnlock(true);
          }}
        >
          Unlock
        </Button>
      );
    }
  }

  function canActivate(tableProps) {
    return props.is_developer_support && tableProps.row.original.activation_state != "active";
  }

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <div className="text-nowrap">
            {tableProps.row.original.deleted_at ? (
              <>
                <i className="fa fa-close me-2 text-danger" title="Deleted User"></i>{" "}
                <i className="text-danger">{tableProps.value}</i>
                <div className="text-danger fst-italic">
                  <small>Deleted {tableProps.row.original.deleted_at}</small>
                </div>
              </>
            ) : (
              <>
                <a href={`/user/${tableProps.row.original.id}`} target="_blank">
                  <i className="fa fa-user me-2"></i>
                </a>
                {tableProps.row.original.full_name}
              </>
            )}
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
        filter: "fuzzyText",
        Cell: (row) => (
          <div>
            <small className="text-muted">{row.value}</small>
          </div>
        ),
      },
      {
        Header: "Activation State",
        accessor: "activation_state",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <div
            className={
              "badge text-uppercase fw-normal bg-" +
              activationStateBadgeClass(tableProps.row.original.activation_state)
            }
          >
            {tableProps.row.original.activation_state}
          </div>
        ),
      },
      {
        Header: "Role",
        accessor: "role",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <div className="badge bg-light text-dark text-uppercase fw-normal">
            {tableProps.row.original.role}
          </div>
        ),
      },
      {
        Header: "340B CE's IDs",
        accessor: "entity_ids",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <small>
            {tableProps.row.original.entities.length > 1 ? (
              <>
                <a
                  href={`/entities/340bid/${tableProps.row.original.entities[0].id_340b}?tab=users`}
                >
                  {tableProps.row.original.entities[0].id_340b}
                </a>
                <a
                  href="#"
                  onClick={() => viewEntities(tableProps.row.original)}
                  className="badge bg-primary text-light ms-2 fw-normal"
                >
                  +{tableProps.row.original.entities.length - 1} more
                </a>
              </>
            ) : tableProps.row.original.entities.length == 1 ? (
              <a href={`/entities/340bid/${tableProps.row.original.entity_ids}?tab=users`}>
                {tableProps.row.original.entity_ids}
              </a>
            ) : null}
          </small>
        ),
      },
      {
        Header: "Prospect",
        accessor: "prospect",
        filter: "fuzzyText",
        Cell: (row) => (
          <div
            className={`badge ${
              !row.value ? "bg-light text-dark" : "bg-danger text-light"
            } fw-normal text-uppercase`}
          >
            {row.value ? "True" : "False"}
          </div>
        ),
      },
      {
        Header: "Locked",
        accessor: "locked",
        filter: "fuzzyText",
        Cell: (row) => (
          <div
            className={`badge ${
              !row.value ? "bg-light text-dark" : "bg-danger text-light"
            } fw-normal text-uppercase`}
          >
            {row.value ? "True" : "False"}
          </div>
        ),
      },
      {
        Header: "Actions",
        Cell: (tableProps) => (
          <div className="text-nowrap">
            {tableProps.row.original.deleted_at ? (
              <React.Fragment>
                {tableProps.row.original.deleted_at ? (
                  <Button
                    className="btn-sm btn-danger"
                    onClick={() => {
                      setShowModalRecover(true);
                      setModalRecoverId(tableProps.row.original.id);
                    }}
                  >
                    Recover
                  </Button>
                ) : (
                  <div></div>
                )}
              </React.Fragment>
            ) : (
              <>
                <a
                  className="btn btn-sm btn-primary"
                  onClick={() => {
                    setModalUpdateUserId(tableProps.row.original.id);
                    setUserToUpdate(tableProps.row.original);
                    setShowModalUpdateUser(true);
                  }}
                >
                  <i className="fa-solid fa-pen"></i>
                </a>
                <a
                  className="btn btn-success btn-sm ms-2"
                  onClick={() => viewSupportModal(tableProps.row.original)}
                >
                  <i className="fa fa-stethoscope"></i>
                </a>
                {canActivate(tableProps) ? (
                  <a
                    className="btn btn-warning btn-sm ms-2"
                    onClick={() => {
                      setModalActivateId(tableProps.row.original.id);
                      setShowActivateModal(true);
                    }}
                  >
                    <i className="fa fa-plug"></i>
                  </a>
                ) : null}
                {props.id_340b ? (
                  <a
                    className="btn btn-danger btn-sm ms-2"
                    onClick={() => {
                      setModalDeleteId(tableProps.row.original.id);
                      setShowDisassociateModal(true);
                    }}
                  >
                    <i className="fa fa-heart-crack"></i>
                  </a>
                ) : null}
                <a
                  className="btn btn-danger btn-sm ms-2"
                  onClick={() => {
                    setShowModalDelete(true);
                    setModalDeleteId(tableProps.row.original.id);
                  }}
                >
                  <i className="fa fa-trash"></i>
                </a>
              </>
            )}
          </div>
        ),
      },
    ],
    [],
  );

  const entity_columns = useMemo(() => [
    {
      Header: "340B ID",
      accessor: "id_340b",
      filter: "fuzzyText",
      Cell: (row) => (
        <a
          className="badge bg-light text-dark fw-normal text-uppercase"
          href={`/entities/340bid/${row.value}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Name",
      accessor: "name",
      filter: "fuzzyText",
      Cell: (row) => (
        <a href={`/entities/340bid/${row.id_340b}`} className="text-dark fw-bold">
          {row.value}
        </a>
      ),
    },
    {
      Header: "Action",
      filter: "fuzzyText",
      Cell: (row) => (
        <a className="btn btn-primary btn-sm" href={`/entities/340bid/${row.id_340b}`}>
          View
        </a>
      ),
    },
  ]);

  function viewEntities(row) {
    setEntitiesTitle(row.full_name + " Entities");
    setUserEntities(row.entities);
    setShowEntitiesModal(true);
  }

  function EntitiesModal(entities) {
    return (
      <ul className="list-group">
        {entities.constructor == Array && entities.length != 0 ? (
          <CustomTable data={entities} columns={entity_columns} />
        ) : (
          <i className="text-muted">
            <br />
            This user has no entities.
          </i>
        )}
      </ul>
    );
  }

  function viewSupportModal(row) {
    setUserToUpdate(row);
    setSupportTitle("Support " + row.full_name);
    setShowSupportModal(true);
  }

  function SupportModal(user) {
    if (user) {
      return (
        <div>
          {user.locked == true ? (
            <div className="alert alert-danger">
              <b>Account Locked</b>
              <p>This account was locked due to exceeding allowed login attempts.</p>
              <UnlockButton unlock_token={user.unlock_token} id={user.id} />
            </div>
          ) : null}
          {user.registration_status == "pending_onboarding" ? (
            <div className="alert alert-primary">
              <b>Onboarding</b>
              <p>
                This user has not yet used the onboarding flow. Send onboarding link in an email to
                allow the user to register.
              </p>
              <a
                href={`/users/${user.id}/email_onboarding_link`}
                className="btn btn-sm btn-primary"
              >
                Resend Onboarding Email
              </a>
              <hr />
              <p>Or, share the following link.</p>
              <div className="highlight bg-light mt-2 rounded border p-3">
                <code className="text-muted user-select-all">
                  {user.onboarding_link}
                  {window.isSecureContext ? (
                    <div className="mt-2">
                      <button
                        className="btn btn-sm btn-secondary"
                        onClick={() => {
                          navigator.clipboard.writeText(user.onboarding_link);
                          alert("Copied!");
                        }}
                      >
                        Copy Link
                      </button>
                    </div>
                  ) : null}
                </code>
              </div>
            </div>
          ) : user.registration_status == "needs_entity_validation" ? (
            <>
              {user.primary_entity_id || user.entities[0]?.id_340b ? (
                <div class="alert alert-primary">
                  <p>
                    This user has been auto-rejected due to missing entity validation for their
                    email domain. Please add or apply the appropriate entity validation for the
                    email
                    {window.isSecureContext ? (
                      <a
                        href="#"
                        onClick={() => {
                          navigator.clipboard.writeText(user.email);
                          alert("Copied!");
                        }}
                      >
                        <i className="fa fa-clipboard me-1 text-success"></i>
                      </a>
                    ) : null}{" "}
                    <b className="user-select-all">{user.email}</b> to allow the user to activate
                    their account.
                  </p>
                  <a
                    href={`/entities/340bid/${
                      user.primary_entity_id || user.entities[0]?.id_340b
                    }?tab=validations`}
                    className="btn btn-primary btn-sm"
                  >
                    Go To Entity Validations
                  </a>
                </div>
              ) : (
                <div className="alert alert-danger">
                  This user has been auto-rejected from registration. Please add an entity
                  validation and inform {user.email} to log in and complete their registration.
                </div>
              )}
            </>
          ) : user.registration_status == "pending_registration" ? (
            <div class="alert alert-primary">
              This user has been invited and is pending activation.
              {user.role == "super_admin" ? (
                <a
                  href={`/users_mgmt/${user.id}/resend_registration_email`}
                  className="btn btn-primary btn-sm mt-2"
                >
                  Resend Registration Email
                </a>
              ) : (
                <a
                  href={`/users/${user.id}/email_onboarding_link`}
                  className="btn btn-primary btn-sm mt-2"
                >
                  Resend Onboarding Email
                </a>
              )}
            </div>
          ) : user.registration_status == "needs_pw_cleared_for_onboarding" ? (
            <div className="alert alert-danger">
              This user needs to have their password cleared for onboarding.
              <Button
                className="btn-sm"
                onClick={() => {
                  document.querySelectorAll(".modal-backdrop")[0].remove();
                  document.querySelector("body").removeAttribute("style");
                  setShowSupportModal(null);
                  setShowModalPW(true);
                  setModalPWId(user.id);
                }}
              >
                Clear Password
              </Button>
            </div>
          ) : user.registration_status == "needs_prospect_setup" ? (
            <div className="alert alert-danger">
              This user needs their registration details and entity validations created.
              <div>
                <a
                  href={`/users_mgmt/${user.id}/prospect_create_registration_details_and_entity_validations`}
                  className="btn btn-primary btn-sm mt-2"
                >
                  Create
                </a>
                <br />
                <small>
                  This action will create the registration details and entity validation for this
                  prospect and assigned the requested covered entity.
                </small>
              </div>
            </div>
          ) : user.registration_status == "taken_covered_entity" ? (
            <div className="alert alert-danger">
              This user would need to be merged into their requested Covered Entity. Please reach
              out to the engineering team.
            </div>
          ) : (
            <div className="alert alert-success">
              {user.registration_status == "registered_pending_activation" ? (
                <p>
                  This user has completed all but their last step in registration. Please prompt
                  user to login and complete their registration.
                </p>
              ) : (
                <p>This user is fully registered and active.</p>
              )}
              <hr />
              <b>Reset Password:</b>
              <p>Send an email to this user with a reset password link.</p>
              <Button
                className="btn-sm btn-success"
                onClick={() => {
                  document.querySelectorAll(".modal-backdrop")[0].remove();
                  document.querySelector("body").removeAttribute("style");
                  setShowSupportModal(null);
                  setShowModalResetPW(true);
                  setModalResetPWId(user.id);
                }}
              >
                Send Reset Password Email
              </Button>
            </div>
          )}
        </div>
      );
    }
  }
  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : data ? (
        <>
          {!props.hide_count ? <RecordCount record_count={data.length} record_type="user" /> : null}
          {data.length > 0 || props.hide_count ? (
            <CustomTable columns={columns} data={data} />
          ) : null}
          <FormModal
            show={showModalUpdateUser}
            title={`Edit User - ${data.find((row) => row.id == modalUpdateUserId)?.email}, ${
              data.find((row) => row.id == modalUpdateUserId)?.id
            }`}
            buttonText="Update User"
            modalId={`edit_user_modal_${modalUpdateUserId}`}
            buttonType="primary"
            fields={<UpdateUserFormFields user={userToUpdate} />}
            method="POST"
            action={`/users/${data.find((row) => row.id == modalUpdateUserId)?.id}`}
            dismiss={() => {
              setShowModalUpdateUser(false);
            }}
          />

          <FormModal
            show={showModalUnlock}
            title="Unlock User Account?"
            buttonText="Unlock"
            buttonType="primary"
            modalId={`unlock_modal_${modalUnlockId}`}
            fields={`Are you sure you want to unlock ${
              data.find((row) => row.id == modalUnlockId)?.full_name
            }'s account?`}
            method="POST"
            action={`/users/${data.find((row) => row.id == modalUnlockId)?.id}/unlock`}
            dismiss={() => {
              setShowModalUnlock(false);
            }}
          />
          {showEntitiesModal ? (
            <Modal
              show={showEntitiesModal}
              title={entitiesTitle}
              modalId="entities_modal"
              body={EntitiesModal(userEntities)}
              buttonText="Ok"
              modalSize="large"
              buttonType="primary"
              dismiss={() => {
                setShowEntitiesModal(false);
              }}
            />
          ) : null}
          <FormModal
            show={showActivateModal}
            title="Activate User"
            buttonText="Activate"
            buttonType="warning"
            modalId={`activate_${modalActivateId}`}
            fields={`Are you sure you want to activate ${
              data.find((row) => row.id == modalActivateId)?.full_name
            }?`}
            method="POST"
            action={`/users/${data.find((row) => row.id == modalActivateId)?.id}/activate`}
            dismiss={() => {
              setShowActivateModal(false);
            }}
          />
          {props.id_340b ? (
            <FormModal
              show={showDisassociateModal}
              title="Remove User Access to Entity Organization"
              buttonText="Remove"
              buttonType="danger"
              modalId={`remove_access_${modalDeleteId}`}
              fields={`Are you sure you want to remove ${
                data.find((row) => row.id == modalDeleteId)?.full_name
              } access from ${props.id_340b} organization?`}
              method="POST"
              action={`/entities/${props.id_340b}/users/${
                data.find((row) => row.id == modalDeleteId)?.id
              }/remove_user_from_organization`}
              dismiss={() => {
                setShowDisassociateModal(false);
              }}
            />
          ) : null}
          <FormModal
            show={showModalPW}
            title="Clear Password"
            buttonText="Clear Password"
            buttonType="danger"
            modalId={`clear_pw_modal_${modalPWId}`}
            fields={`Are you sure you want to clear ${
              data.find((row) => row.id == modalPWId)?.full_name
            }'s password?`}
            method="POST"
            action={`/users/${data.find((row) => row.id == modalPWId)?.id}/clear_password`}
            dismiss={() => {
              setShowModalPW(false);
            }}
          />
          <FormModal
            show={showModalResetPW}
            title="Send Reset Password Email"
            buttonText="Send Reset Password Email"
            buttonType="success"
            modalId={`reset_pw_modal_${modalPWId}`}
            fields={`Are you sure you want to send a reset password email to ${
              data.find((row) => row.id == modalResetPWId)?.email
            }?`}
            method="POST"
            action={`/users/${data.find((row) => row.id == modalResetPWId)?.id}/reset_password`}
            dismiss={() => {
              setShowModalResetPW(false);
            }}
          />
          <Modal
            show={showSupportModal}
            title={supportTitle}
            modalId="support_modal"
            body={SupportModal(userToUpdate)}
            buttonText="Ok"
            buttonType="primary"
            action={() => {
              setShowSupportModal(false);
            }}
            dismiss={() => {
              setShowSupportModal(false);
            }}
          />
          <FormModal
            show={showModalDelete}
            title="Delete User"
            buttonText="Delete"
            buttonType="danger"
            modalId={`delete_blocked_${modalDeleteId}`}
            method="POST"
            fields={`Are you sure you want to delete ${
              data.find((row) => row.id == modalDeleteId)?.email
            }`}
            action={`/user/${data.find((row) => row.id == modalDeleteId)?.id}/destroy`}
            dismiss={() => {
              setShowModalDelete(false);
            }}
          />
          <FormModal
            show={showModalRecover}
            title={`Recover User - ${data.find((row) => row.id == modalRecoverId)?.email}, ${
              data.find((row) => row.id == modalRecoverId)?.id
            }`}
            buttonText="Recover"
            modalId={`recover_user_modal_${modalRecoverId}`}
            buttonType="primary"
            fields={`Are you sure you want to recover ${
              data.find((row) => row.id == modalRecoverId)?.full_name
            }'s account?`}
            method="POST"
            action={`/deleted_user/${modalRecoverId}/recover`}
            dismiss={() => {
              setShowModalRecover(false);
            }}
          />
        </>
      ) : null}
    </React.Fragment>
  );
};

export default Users;
