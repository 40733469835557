import React from "react";
import { Form } from "react-bootstrap";
import JiraTicket from "../JiraTicket";

export default function DeleteDesignationFormFields(props) {
  return (
    <React.Fragment>
      <p>{`Are you sure you want to delete ${props.id_340b}, ${props.pid}, ${props.manufacturer_name} designation?`}</p>
      <JiraTicket/>
      <input type="hidden" value="delete" name="_method"></input>
    </React.Fragment>
  );
};
