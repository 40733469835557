import React from "react";
import JiraTicket from "../JiraTicket";

export default function DeleteLdnFormFields(props) {
  return (
    <React.Fragment>
      <p>{`Are you sure you want to delete ${props.network} network, PID:${props.pid}, with ${props.manufacturer_name} manufacturer?`}</p>
      <JiraTicket />
      <input type="hidden" value="delete" name="_method"></input>
    </React.Fragment>
  );
}
