import React from "react";
import { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";

export default function ImportLdnsForm(props) {
  const [networkOptions, setNetworkOptions] = useState([]);
  const [manufacturer, setManufacturer] = useState(null);
  const [network, setNetwork] = useState(null);

  function fileIsXLSX(inputFile) {
    if (inputFile) {
      const extName = inputFile.files[0].name.split(".").pop();
      if (extName != "xlsx") {
        alert("File must have extension .xlsx");
        inputFile.value = null;
      }
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    const inputFile = event.target.file.files[0];
    const url = props.upload_url;
    const formData = new FormData();
    formData.append("file", inputFile);
    formData.append("manufacturer", event.target.manufacturer.value);
    formData.append("network", event.target.network.value);
    fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
      },
      body: formData,
    }).then((res) => {
      if (res.status >= 200 && res.status <= 299) {
        location.reload();
      }
    });
  }

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit} id="pidUploadForm">
        <Form.Group className="mb-3" controlId="manufacturer">
          <Form.Label>
            Manufacturer <small className="text-danger">*</small>
          </Form.Label>
          <Form.Select
            required
            name="manufacturer"
            onChange={(e) => {
              setManufacturer(e.target.value);
              const man_network = props.manufacturer_networks.find((mn) => mn.manufacturer == e.target.value);
              setNetworkOptions(man_network?.networks || []);
            }}>
            <option value="">{"< Make a Selection >"}</option>
            {props.manufacturers.map((manufacturer) => (
              <option key={manufacturer.key} value={manufacturer.key}>
                {manufacturer.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3" controlId="network">
          <Form.Label>
            Network <small className="text-danger">*</small>
          </Form.Label>
          <Form.Select required name="network" onChange={(e) => setNetwork(e.target.value)}>
            <option value="">{"< Make a Selection >"}</option>
            {manufacturer == "novo_nordisk" || manufacturer == "boehringer_ingelheim" ? (
              <option key="ldn" value="ldn">
                LDN
              </option>
            ) : (
              <>
                {networkOptions.map((network) => (
                  <option key={network} value={network}>
                    {network}
                  </option>
                ))}
              </>
            )}
          </Form.Select>
        </Form.Group>
        <Form.Group controlId="headers" className="mb-3 p-3 bg-body-tertiary rounded border shadow-sm">
          <Form.Label className="text-muted smaller">Required LDN File Headers:</Form.Label>
          <br />
          <b className="text-primary">MANUFACTURER, NETWORK, PID</b>
          <br />
          <br />
          <Form.Label className="text-muted smaller">Required LDN File Type:</Form.Label>
          <br />
          <b className="text-primary">.xlsx (Excel)</b>
          <br />
          <br />
          <Form.Label className="text-muted smaller">File To Upload:</Form.Label>
          <Form.Control required name="file" type="file" onChange={(e) => fileIsXLSX(e.target)} />
        </Form.Group>
        <p className="text-danger">
          <b>Warning:</b> This file is meant to be a complete list of LDNs for this manufacturer and network. Any LDN not in the file for this manufacturer and network will be <b>removed</b>.
        </p>
        <p className="text-danger">
          <b>Please double check that this file is complete before submitting.</b>
        </p>
        <Button type="submit">Upload LDN File</Button>
      </Form>
    </React.Fragment>
  );
}
