import React from "react";
import Activities from "../Activities";
import titleCase from "../utils/SnakeToTitleCase";
import Entities from "../Entities/Entities";
import Designations from "../Designations/Designations";
import { Tabs, Tab } from "react-bootstrap";
import SubmissionRows from "../SubmissionRows";

const User = (props) => {
  return (
    <>
      <div className="pt-3 pb-2 mb-3">
        <h1 className="h2">
          <i className="fa fa-user me-2"></i>
          {props.display_name}
          <div className="badge bg-light text-muted ms-2">
            {titleCase(props.user.role)}
          </div>
        </h1>
        <p className="text-muted">Member since {props.user.created_at}</p>
      </div>
      <Tabs
      defaultActiveKey={props.params.tab || "activities"}
      id="user_tabs"
      className="mb-3"
      >
      <Tab eventKey="activities" title={<><i className="fa fa-list"></i> All Activity</>}>
        <div className="recent-activity">
          <Activities activities_path={props.activities_path} />
        </div>
      </Tab>
      <Tab eventKey="entities" title={<><i className="fa fa-hospital"></i> Covered Entities <span className="badge bg-light text-dark">{props.entities.length}</span></>}>
        <div className="entities">
          <p className="text-muted">All covered entities assigned to this user.</p>
          <Entities entities={props.entities} />
        </div>
      </Tab>
      { props.designations_path ?
      <Tab eventKey="designations" title={<><i className="fa fa-map-pin"></i> Designations <span className="badge bg-light text-dark">{props.designations_count}</span></>}>
        <div className="designations">
        <p className="text-muted">All designations created by this user.</p>
          <Designations designations_path={props.designations_path} />
        </div>
      </Tab>
      : null }
     </Tabs>
    </>
  );
};

export default User;
