import React from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import FormModal from "../FormModal";
import NewEffectiveDateForm from "./NewEffectiveDateForm";

const CreateEffectiveDate = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [modalEditId, setModalEditId] = useState(props.id_340b)

  return (
    <React.Fragment>
      <Button
        variant="primary"
        className="ms-2 btn-sm"
        onClick={() => {
          setModalShow(true);
          setModalEditId(props.id_340b);
        }}>
        <i className="fa fa-calendar-plus me-1"></i>
        Create Effective Date
      </Button>
      <FormModal
        show={modalShow}
        title="Create Effective Date"
        buttonText="Create"
        buttonType="primary"
        modalId={`effective_date_modal_${modalEditId}`}
        fields={<NewEffectiveDateForm 
                  manufacturers={props.manufacturers} 
                  manufacturer_networks={props.manufacturer_networks} 
                  id_340b={props.id_340b} />}
        method="POST"
        action="/effective_dates/create"
        dismiss={() => {
          setModalShow(false);
        }}
      />
    </React.Fragment>
  );
};

export default CreateEffectiveDate;
