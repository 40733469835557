import React from "react";
import { useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import _ from "lodash";
import { numberWithCommas } from "../utils/NumberFormatter";
import Modal from "../Modal";
import { Form } from "react-bootstrap";

const AtRiskReport = (props) => {
  const [showProductsModal, setShowProductsModal] = useState(false);  
  const [modalEditId, setModalEditId] = useState(null);
  const [networkOptions, setNetworkOptions] = useState(props.manufacturer_networks.find((mn) => mn.manufacturer == props.selected_manufacturer).networks);
  const [thresholdOutput, setThresholdOutput] = useState(props.selected_threshold * 100);
  const [policyDate, setPolicyDate] = useState(props.selected_policy_date)

  function formatData(data, suspensions, exceptions) {
    var formatted_data = [];

    _.each(data, (item) => {
      _.each(item.pharmacies, (pharmacy) => {
        formatted_data.push({
          id_340b: item.id_340b,
          entity_name: item.entity_name,
          pid: pharmacy.pid,
          suspended: _.find(suspensions[item.id_340b], ["pid", pharmacy.pid]) ? true : false,
          exception: _.find(exceptions[item.id_340b], ["pid", pharmacy.pid]) ? true : false,
          pharmacy_name: pharmacy.pharmacy_name,
          pharmacy_address: pharmacy.pharmacy_address,
          compliance_counter: pharmacy.compliance_counter,
          counter_date: pharmacy.counter_date,
          purchases: _.sumBy(pharmacy.products, (cp) => cp.debits_wac),
          claims: _.sumBy(pharmacy.products, (cp) => cp.credits_wac),
          balance: _.sumBy(pharmacy.products, (cp) => cp.debits_wac + cp.credits_wac),
          last_submission: pharmacy.last_submission,
          products: pharmacy.products
        });
      });
    });  
    return _.sortBy(formatted_data, ["balance"]);
  };
  const [entities, setEntities] = useState(formatData(props.accounts, props.suspensions, props.exceptions));  

  const getRatio = (claims, purchases) => {
    if (purchases == 0) {
      return 100;
    }

    return (Math.abs(claims) / Math.abs(purchases)) * 100;
  };

  function getPercentage(pharmacy) {
    return Math.round(
      getRatio(
        pharmacy.claims,
        pharmacy.purchases
      )
    )
  }  

  const [data, setData] = useState(
    useMemo(
      () =>
        entities.
        map((obj) => {
          return {
            ...obj
          };
        }),
      []
    )
  );

  const columns = useMemo(
    () => [
      {
        Header: "Covered Entity",
        accessor: (row) => `${row.entity_name} ${row.id_340b}`,
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <>
          <b>{tableProps.row.original.id_340b}</b>
          <div><small className="text-muted">{tableProps.row.original.entity_name}</small></div>
          </>
        )
      },
      {
        Header: "Contract Pharmacy",
        accessor: "pharmacy_name",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <>
          <b>{tableProps.row.original.pharmacy_name}</b>
          <div><small className="text-muted">{tableProps.row.original.pharmacy_address}</small></div>
          </>
        )
      },
      {
        Header: "Status",
        accessor: "",
        Cell: (tableProps) => (
          <small className="badge bg-secondary">
            {tableProps.row.original.suspended ? "SUSPENDED" : tableProps.row.original.exception ? "EXCEPTION" : null}
          </small>
        )
      },
      {
        Header: "Counter",
        accessor: "compliance_counter",
        Cell: (tableProps) => (
          <small className="text-muted">
            {tableProps.row.original.compliance_counter} days
          </small>
        )
      },
      {
        Header: "Percentage",
        accessor: "",
        Cell: (tableProps) => (
          <small className="text-muted">
            {getPercentage(tableProps.row.original)}%
          </small>
        )
      },
      {
        Header: "View",
        accessor: "",
        Cell: (tableProps) => (
          <div 
            onClick={() => {
              setShowProductsModal(true);
              setModalEditId(tableProps.row.original.id_340b);
            }} 
            className="btn btn-sm btn-primary">
            <i className="fa fa-search"></i>
          </div>
        )                
      }
    ],
    []
  );

  function ProductsModal(entity) {
    return (
      <>
      { entity ?
        <table className="table table-striped">
        <thead>
          <th><small>Product Name</small></th>
          <th><small>Last Submission</small></th>
          <th><small>Purchased Units</small></th>
          <th><small>Submitted Units</small></th>
          <th><small>Purchases</small></th>
          <th><small>Claims</small></th>
        </thead>
          {entity.products.map((row, i) => {
            return (
              <tr key={i}>
                <td>
                  <div>
                    <small>
                      {row.product_name.toUpperCase()}
                    </small>
                  </div>
                </td>
                <td><small>{row.last_submission ? row.last_submission : "--"}</small></td>
                <td><small>{numberWithCommas(row.debits_qty)}</small></td>
                <td><small>{numberWithCommas(row.credits_qty)}</small></td>
                <td><small>${numberWithCommas(row.debits_wac)}</small></td>
                <td><small>${numberWithCommas(row.credits_wac)}</small></td>
              </tr>
            );
          })}
        </table>
    : null }
    </>
    )
  }

  return (
    <React.Fragment>
      <div className="jumbotron p-4 mb-4 bg-body-tertiary rounded-4">
        <Form>
          <div className="row">
            <Form.Group className="col-3" controlId="manufacturer_name">
              <Form.Label>Manufacturer</Form.Label>
              <Form.Select
                required
                name="manufacturer_name"
                onChange={(e) => {
                  const man_network = props.manufacturer_networks.find((mn) => mn.manufacturer == e.target.value);
                  setNetworkOptions(man_network?.networks || []);
                  const policy_date_obj = props.policy_dates.find((mn) => mn.manufacturer == e.target.value);
                  setPolicyDate(policy_date_obj?.policy_date || policyDate);
                }}>
                <option value="">{"< Make a Selection >"}</option>
                {props.manufacturers.map((manufacturer) => (
                  <option key={manufacturer.key} value={manufacturer.key} selected={props.selected_manufacturer == manufacturer.key ? "selected" : null}>
                    {manufacturer.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-3" controlId="network">
              <Form.Label>Network</Form.Label>
              <Form.Select required name="network">
                <option value="">{"< Make a Selection >"}</option>
                {networkOptions.map((network) => (
                  <option key={network} value={network} selected={props.selected_network == network ? "selected" : null}>
                    {network?.toUpperCase()}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="col-2" controlId="threshold">
              <Form.Label>
                Threshold
                <output className="ms-2 text-primary" id="threshold_output">
                  {thresholdOutput}
                </output>%
              </Form.Label>
              <div className="p-2 pb-0 bg-white rounded-2">
                <Form.Range className="mb-0" min="0" max="1" step="0.1" name="threshold" defaultValue={props.selected_threshold} onInput={(e) => {setThresholdOutput(e.target.value * 100)}} />
              </div>
            </Form.Group>
            <Form.Group className="col-2">
              <Form.Label>Policy Date</Form.Label>
              <div className="p-2 bg-white rounded-2" id="selected_policy_date">
                <center className="text-muted">{policyDate}</center>
              </div>
            </Form.Group>
            <Form.Group className="col-2">
              <br />
              <input type="submit" className="btn btn-primary mt-2" value="Refresh Report" />
            </Form.Group>
          </div>
        </Form>
      </div>
      <CustomTable columns={columns} data={data} />
      <Modal
        show={showProductsModal}
        dynamicWidth={true}
        dynamicHeight={true}        
        title={`Products for: ${
          data.find((row) => row.id_340b == modalEditId)?.pharmacy_name
        }`}
        modalId={`products_modal_${modalEditId}`}
        buttonText="Ok"
        buttonType="primary"
        modalSize="large"
        body={ProductsModal(data.find((row) => row.id_340b == modalEditId))}
        action={() => {
          setShowProductsModal(false);
        }}
        dismiss={() => {
          setShowProductsModal(false);
        }}
      />      
    </React.Fragment>
  );
};

export default AtRiskReport;
