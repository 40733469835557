import React from "react";
import { useState } from "react";
import FormModal from "../FormModal";
import { Button } from "react-bootstrap";
import NewBlockedForm from "./NewBlockedForm";

const BlockListHeader = (props) => {
  const [showModalAdd, setShowModalAdd] = useState(false);

  return (
    <React.Fragment>
      <div className="pt-3 pb-2 row">
        <div className="col-10">
          <h1 className="h2">
            <i className="fa fa-lock pe-2"></i>
            Block List
          </h1>
        </div>
        { props.can_edit ?
          <div className="col-2 text-end">
            <Button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setShowModalAdd(true);
              }}>
              <i className="fa fa-plus pe-2"></i>
              Add To Block List
            </Button>
          </div>
        : null }
      </div>
      <p className="text-muted">Manufacturers 340B pricing restriction by entity type or network.</p>
      <div className="d-flex flex-column">
      <small className="p-2">Entity: Blocked at the Covered Entity's 340B ID</small>
      <small className="p-2">Pharmacy: Blocked at the pharmacy level by PIDs</small>
      <small className="p-2">Type Code: Blocked at the type of Covered Entity</small>
      </div>
      <FormModal
        show={showModalAdd}
        title="Add Blocked Item"
        buttonText="Submit"
        buttonType="primary"
        modalId="add_blocked_modal"
        fields={
          <NewBlockedForm manufacturers={props.manufacturers} manufacturer_networks={props.manufacturer_networks} />
        }
        action="/blocked"
        method="POST"
        dismiss={() => {
          setShowModalAdd(false);
        }}
      />
    </React.Fragment>
  );
};

export default BlockListHeader;
