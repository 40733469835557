import React from "react";
import Activities from "./Activities";

const Dashboard = (props) => {
  return (
    <>
      <div className="recent-activity">
        <h3 className="pb-2">
          <i className="fa fa-list me-2"></i>
          Recent Activity
        </h3>
        <Activities activities_path={props.activities_path} />
      </div> 
    </>
  );
};

export default Dashboard;
